.left-side {
    display: flex;
    /* justify-content: start; */
    align-items: center;
    flex-direction: column;
    padding: 0 25px;
    /* margin-top: 20px; */

    position: sticky;
    top: 0px;
    height: 100vh;
    /* width: 240px; */
    min-width: 205px;
}



.twigyy-logo-side-bar {
    height: 45px;
    /* margin-top: 10px; */
    margin-top: 30px;
    /* filter: drop-shadow(4px 4px 0px #adadad); */
}
.left-side > div {
    margin-bottom: auto;
    cursor: pointer;
}

.side-bar > div {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    /* background-color: #38424B; */
    background-color: #14283a;
    /* padding: 10px 15px;
    gap: 30px;
    border-radius: 10px;        */
    gap: 25px;
    padding: 20px 15px;
    border-radius: 50px;
}
.side-bar > div button{
    display: flex;
    border: none;
    align-items: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
}
.side-bar > div img {
    height: 28px;
    /* padding: 8px; */
}
.side-bar > div > a {
    padding: 8px;
}
.side-bar-normal-icon {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.side-bar-normal-icon > img {
    /* padding: 8px; */
}
.side-bar-focus-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 1s, border-radius 2s;
    background-color: #B9F53B;
    border-radius: 50%;
}
.side-bar-focus-icon > img {
    transition: 0.5s filter;
    /* padding: 8px; */
    border: none;
    filter: invert();
    /* filter: brightness(0) saturate(100%) invert(84%) sepia(100%) saturate(385%) hue-rotate(20deg) brightness(95%) contrast(102%); */
}

/* Mobile Nave bar */
/* .mobile-nav-outlet {
    display: none;
    position: absolute;
    width: 50%;
    height: 100vh;
}
.mobile-nav-outlet {
        display: contents;
    }
 */

@media screen and (max-width: 570px) {
    .left-side {
        display: none;
    }
}