.login-header {
    /* max-height: 95vh; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 20px;
    overflow: hidden;
    /* margin-top: 20px; */
}

.logo-twigyy {
    height: 60px;
    margin-top: 10px;
}

.login {
    font-family: 'Ubuntu', sans-serif;
    font-size: 35px;
    margin:0px;
    margin-top: 50px;
    letter-spacing: 4px;
}

.user-input {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
}
.email-input {
    font-family: 'Ubuntu', sans-serif;
    height: 36px;
    width: 300px;
    text-align: center;
    font-size: 16px;
    border: 3px solid rgb(189, 250, 60);
    border-radius: 20px;
    margin-bottom: 20px;
    background-color: transparent;
    outline: none;
}
.password-input {
    font-family: 'Ubuntu', sans-serif;
    height: 36px;
    width: 300px;
    text-align: center;
    font-size: 16px;
    border: 3px solid rgb(189, 250, 60);
    border-radius: 20px;
    background-color: transparent;
}
.password-input[type='password']:focus {
    --tw-ring-color: #ffffff00;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    border-color: rgb(189, 250, 60);
}
.email-input, .password-input:focus {
    outline: none;
}

.footer-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.new-in-twigyy {
    margin-top: 60px;
    margin-bottom: 40px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 13px;
    
}

.down-login-with {
    margin-bottom: 30px;
}
.login-with {
    font-family: 'Ubuntu', sans-serif;
    font-size: 12px;
    display:inline;
    margin-left: 50px;
    margin-right: 50px;
}
.down-login-with > hr {
    margin: 0;
    margin-bottom: 2px;
    display:inline-block;
    width: 150px;
    /* border: 1px solid; */
    border-radius: 1px;
}

.logo > button > img {
    height: 40px;
    width: 40px;
}
.logo > button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.continue-button{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    text-align: center;
}
.continue-btn {
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 20px;
    padding: 10px 40px;
    border: 1px solid rgb(188,187,187);
    border-radius: 10px;
    background-color: rgb(189, 250, 60);
    cursor: pointer;
    transition: opacity 0.15s;
}
.continue-btn:hover{
    opacity: 0.8;
}
.continue-btn:active {
    opacity: 0.6;
}

.login-with-ht {
    height: 100%;
    position: relative;
}

.continue-btn-rltive {
    position: absolute;
    bottom: 10px;
}
.continue-button button{
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 20px;
    padding: 10px 70px;
    margin-bottom: 10px;

    border: 1px solid rgb(188,187,187);
    border-radius: 10px;
    background-color: rgb(189, 250, 60);
    cursor: pointer;
    transition: opacity 0.15s;
}


.new_pass_input {
    font-family: 'Ubuntu', sans-serif;
    height: 36px;
    width: 300px;
    /* text-align: center; */
    font-size: 16px;
    border: 3px solid rgb(189, 250, 60);
    border-radius: 20px;
    background-color: transparent;
    position: relative;
}
.new_pass_input > input {
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 16px;
    border: none;
    border-radius: 20px;
    background-color: transparent;
}
.new_pass_input > input[type='password']:focus {
    --tw-ring-color: #ffffff00;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    border-color: rgb(189, 250, 60);
}
.new_pass_input > button {
    height: 100%;
    position: absolute;
    right: 10px;
    /* background-color: rgba(255, 255, 255, 0.308); */
}

@media screen and (min-width: 320px) and (max-width: 570px) {
    .logo-twigyy {
        height: 50px;
    }
    .login {
        margin-top: 10px;
        font-size: 25px;
    }
    .user-input {
        margin-top: 20px;
    }
    .new-in-twigyy {
        margin-top: 10px;
    }
    .down-login-with > hr {
        width: 100px;
    }
    .login-with {
        margin-left: 25px;
        margin-right: 25px;
    }
    .continue-button button {
        margin-top: 100px;
        padding: 8px 30px;
    }
}