/* .login-header {
    width: 500px;
    margin: auto;
    position: relative;
    top: 40px;
} */


.check-msg > * {
    text-align: center;
    font-family: 'Ubuntu', sans-serif;
    
}
.p1 {
    font-size: 40px;
    font-weight: 400;
    margin-top: 10px;
}

.verify-btn{
    text-align: center;
    margin-top: 350px;
}

/* .verify-continue-button > link > button {
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 20px;
    padding: 10px 40px;
    border: 1px solid rgb(188,187,187);
    border-radius: 10px;
    background-color: rgb(189, 250, 60);
    cursor: pointer;
    transition: opacity 0.15s;
}
.verify-continue-button > button:hover{
    opacity: 0.8;
}
.verify-continue-button > button:active {
    opacity: 0.6;
} */