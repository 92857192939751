@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

* {
  font-family: 'Ubuntu', sans-serif;
}
body {
  /* height: 100vh;
  width: 100vw; */
  height: max-content;
  padding: 0;
  margin: 0;
  font-style: 'Ubuntu', sans-serif;
  /* position: relative;  */
  
  
}
.body-dark{
  /* background: linear-gradient(204deg, #485D62 1.94%, #2F373A 13.84%, #222A2D 22.69%, #1F272B 27.56%, #1B2125 40.32%, #171B21 54.16%, #12171E 97.64%); */
  background: #04121a;
  background-repeat: no-repeat;
}
/* button {
  color: white!important;
}
p {
  color: white;
} */

/* h2{
  font-size: 1.875rem;
  font-weight: bold;
} */
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.dark-test {
  background-color: #151515!important;
  border: 1px solid #000000!important;
  box-shadow: 1px 1px 18px 2px rgb(21, 21, 21)!important;
  color: white;
}