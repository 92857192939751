.loader-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.loader-grp {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    position: relative;
    height: 200px;
    width: 200px;
}

.loader-icon-sec {
    /* background-color: rgba(255, 0, 0, 0.445); */
    /* height: 250px;
    width: 250px; */
    height: 300px;
    width: 300px;
    position: absolute;
    top: -50px;
    animation: loader-rotate 2s linear ;
}
.loader-icon-sec > img {
    height: 90px;
    position: absolute;
    /* right: -35px;
    top: -30px; */
    top: 25px;
    right: -25px;
}

@keyframes loader-rotate {
    0% {
        opacity: 0;
        rotate: 0deg;
    }
    100% {
        opacity: 1;
        rotate: 360deg;
    }
}

/* loader T */

.loader-t {
    /* display: none; */
    height: 200px;
    animation: loader-opacity 2s linear;
    /* transition: opacity 1s; */
    /* filter: opacity(1); */
    /* z-index: 2; */
}

@keyframes loader-opacity {
    0% {
        opacity: 0;
    }
    /* 30% {
        opacity: 0;
    } */
    100% {
        opacity: 1;
    }
}
.loader-text {
    display: flex;
    gap: 20px;
    font-size: 15px;
    position: absolute;
    bottom: 50px;
    animation: loader-opacity 2s ease-in;
}
@keyframes loader-txt-opacity {
    0% {
        opacity: 0;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}