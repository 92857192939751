/* body{
    padding: 0;
    margin: 0;
} */
.reel_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Ubuntu',sans-serif;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
}
/* .left-sec {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 20px 30px 0px 30px;
} */
/* .reel-middle-sec { */
    /* background-color: #8A8C8E;
    border-radius: 50px; 
    padding: 20px 20px;*/
    /* margin-left: 50px; */
    /* position: relative; */

    /* width: 40%; */
/* } */
.reel-middle-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    /* gap: 5px; */
    height: 100%;
    width: 100%;
    min-width: 300px;
    /* padding-top: 20px; */
    overflow: scroll;
    scrollbar-width: none;
    scroll-snap-type: y mandatory;
}
.reel-right-sec {
    padding: 30px;
}

.reel-middle-sec > .reel-scroll-div:nth-child(1) {
    margin-top: 40px;
}

/* Left side css */
/* .twigyy-logo {
    height: 50px;
    filter: drop-shadow(4px 4px 0px #adadad);
}
.left-sec > div {
    margin-bottom: auto;
}
.nav-icon {
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: space-between;
    align-items: center;
    background-color: #38424B;
    padding: 18px 15px;
    border-radius: 10px;       
}

.nav-icon > button{
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}
.normal-icon {
    background-color: transparent;
    border: none;
    border-radius: 50%;
}
.focus-icon {
    background-color: #B9F53B;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: none;
}
.focus-icon > img {
    filter: invert();
}
.nav-icon img {
    height: 35px;
} */

/* Middle sec css */
.action-mobile {
    display: none;
    /* width: fit-content; */
    /* height: fit-content; */
    position: absolute;
    right: 10px;
    bottom: 100px;
}

.reel-scroll-div {
    display: flex;
    height: 90%;
    width: 500px;
    margin-top: 20px;
    scroll-snap-align: center;
}
.reel-middle-sec > div > div {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    border-radius: 20px;
    /* overflow: hidden; */
}

.reel-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    /* margin-bottom: 20px; */
    position: absolute;
    right: -5px;
    top: 10px;
    /* width: 100%;
    min-width: 400px; */
    padding: 10px 25px;
    z-index: 10;
}
/* .reel-header > p {
    margin: 0;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 5px;
    color: white;    
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: black;
} */
.reel-header > button {
    margin: 0;
    padding: 0;
    height: fit-content;
    margin-left: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.reel-header > button > img {
    height: 20px;
    /* filter: invert(); */
}
.reel-middle-sec > div > div > video::-webkit-media-controls{
    display: none !important;
}
.play_pause_btn {
    width: 100%;
    height: 100%;
    position: absolute;
    /* background-color: red;
    top: 50%;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
  }

.video_progresbar {
    width: 100%;
    height: 18px;
    position: absolute;
    bottom: 0;
}
.video_progresbar > input[type=range] {
    width: 100%;
    /* -webkit-appearance: none; */
    height: 5px;
    background: #ddd; 
    border-radius: 5px;
    outline: none; 
    opacity: 0.7; 
    transition: opacity 0.3s;
}
.video_progresbar > input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none; 
    appearance: none;
    opacity: 0;
}
.video_progresbar > input[type=range]::-webkit-slider-runnable-track {
    /* -webkit-appearance: none; */
    height: 8px;
    /* background: #ddd; */
    border-radius: 5px;
}

.reel-middle-sec > div > div > video{
    border-radius: 20px;
    height: 100%;
    width: 100%;
    max-width: 400px;
    min-width: 400px;
    object-fit: contain;
    /* border: 1px solid black; */
}

/* Action sec css */
.action-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 15px;
    height: 90%;
    margin-left: 20px;
}

.action-sec > button{
    background-color: transparent;
    border: none;
}

.action-sec > button > img{
    cursor: pointer;
    height: 28px;
    width: 30px;
}
.action-sec > button > p {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
}


.action-sec > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.action-sec > div > button {
    background-color: transparent;
    border: none;
}
.music-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
}
.music-bg-img {
    height: 45px;
    width: 45px;
    opacity: 0.9;
    filter: brightness(80%);
    border-radius: 50%;
    /* border: 5px solid #B9F53B; */
}

.music-btn > img:nth-child(2){
    position: absolute;
    filter: invert();
}


.more-music-btn > img {
    border-radius: 10px;
    height: 50px;
    width: 50px;
    object-fit: cover;
}


/* right sec css */
.reel-right-sec > :nth-child(1) {
    letter-spacing: 2px;
    line-height: 35px;
}

.reel-right-sec > :nth-child(1) > p {
    font-size: 16px;
    font-weight: 600;
}

.profile-name {
    margin-left: 40px;
}

.reel-right-sec > hr {
    border: 1px solid #8A8C8E;
    border-radius: 1px;
}

.comment-sec {
    display: flex;
    flex-direction: column;
    margin-left: 80px;
    margin-top: 30px;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 45px;
}
.comment {
    display: flex;
}
.comment > p {
    margin: 0;
}
.comment > p:first-child {
    margin-right: 20px;
    font-weight: 600;
}

.threedot-rel {
    position: relative;
}
.reel-threedot {
    top: -325px;
    right: -260px;
    z-index: 10;
}

.reel-cmnt {
    position: absolute;
    /* top: 0px; */
    /* right: -400px; */
    right: 50px;
    bottom: 0px;
    border-radius: 10px;
    height: 100%;
    width: 450px;
    z-index: 100;
    justify-content: start;
    padding: 10px 0px;
}
.reel-cmnt > div:nth-child(3) {
    height: 85%;
    overflow: scroll;
    scrollbar-width: none;
}
.reel-cmnt > div:last-child {
    margin-top: auto;
}
.reel-cmnt-close {
    position: absolute;
    top: 10px;
    right: 10px;
}



.reel-share-box {
    position: absolute;
    bottom: 0;
    /* right: -400px; */
    right: 50px;
    height: 100%;
    /* width: 50%; */
    width: 450px;
    /* background-color: #38424B; */
    border-radius: 10px;
    box-shadow: 1px 4px 15px 7px rgba(0, 0, 0, 0.44);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
}
.reel-share-box > section:nth-child(2) {
    display: flex;
    flex-direction: column;
    width: 92%;
}
.reel-share-box > section:nth-child(2) > hr {
    width: 8%;
    border: 3px solid white;
    border-radius: 5px;
    align-self: center;
    margin-top: 5px;
}
.reel-share-box > section:nth-child(2) > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.reel-share-box > section:nth-child(2) > div > p {
    font-size: 30px;
    color: white;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}
.reel-share-box > section:nth-child(2) > div > hr {
    width: 100%;
    border: 1px solid white;
    border-radius: 2px;
    margin-bottom: 20px;
}
.reel-share-box > section:nth-child(2) > div > input {
    width: 95%;
    font-size: 18px;
    background-color: #DDD7D7;
    border: none;
    outline: none;
    letter-spacing: 2px;
    border-radius: 20px;
    padding: 2px 20px;
}

.reel-share-box > section:nth-child(3) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}
.reel-share-box > section:nth-child(3) > div:nth-child(1) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    height: 300px;
    overflow: scroll;
    scrollbar-width: none;
    margin-top: 20px;
    /* background-color: red; */
}
.reel-share-box > section:nth-child(3) > div:nth-child(1) > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100px;
    /* height: 100px;
    overflow: hidden; */
    margin: auto;
}
.reel-share-box > section:nth-child(3) > div:nth-child(1) > div > img {
    height: 55px;
    width: 55px;
    border-radius: 50%;
}
.reel-share-box > section:nth-child(3) > div:nth-child(1) > div > p {
    margin: 0;
    color: white;
    font-size: 15px;
    text-wrap: wrap;
    text-align: center;
    height: 18px;
    overflow: hidden;
}
.reel-share-box > section:nth-child(3) > button {
    width: 98%;
    margin-top: 20px;
    background-color: #BDFA3C;
    border: 1px solid black;
    padding: 2px 0px;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
.reel-share-box > section:nth-child(3) > div:nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 10px;
} 
.reel-share-box > section:nth-child(3) > div:nth-child(3) > p {
    width: 81%;
    padding: 8.5px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #000000;
    color: #FFFFFF;
    margin: 0;
    overflow: hidden;
}
.reel-share-box > section:nth-child(3) > div:nth-child(3) > button {
    display: flex;
    height: 41px;
    width: 90px;
    align-items: center;
    justify-content: space-between;
    /* gap: 15px; */
    border: 1px solid black;
    background-color: #0284FD;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 0px 4px;
    font-size: 15px;
    font-weight: 500;
    /* letter-spacing: 2px; */
    cursor: pointer;
}
.reel-share-box > section:nth-child(3) > hr {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.reel-share-box > section:nth-child(4) {
    display: flex;
    justify-content: space-around;
    /* gap: 10px; */
    width: 95%;
    margin-top: auto;
    margin-bottom: 15px;
    overflow: scroll;
    scrollbar-width: none;
}
.reel-share-box > section:nth-child(4) > button {
    background-color: transparent;
    border: none;
    cursor: pointer;

}
.reel-share-box > section:nth-child(4) > button > img {
    height: 30px;
    min-width: 30px;
}


.reel-scroll-div > .action-display > div {
    opacity: 0;
    transition: opacity 0.4s;
}
.reel-scroll-div:hover > .action-display > div {
    opacity: 1;
    transition: opacity 0.4s;
}


@media screen and (min-width: 320px) and (max-width: 570px) {

    .reel-cmnt {
    position: absolute;
    top: 0px;
    right: -5px;
    border-radius: 0px;
    height: 100vh;
    width: 100vw;
    z-index: 10;
    }
    .action-display {
        display: none !important;
        width: 0px;
    }
    .action-mobile {
        display: contents;
    }
    .action-mobile > div{
        z-index: 1;
        position: absolute;
        right: 5px;
    }
    .reel_main {
        width: 100%;
    }
    .reel-scroll-div {
        justify-content: center;
        /* align-items: center; */
        height: 100%;
        width: fit-content;
    }
    .reel-scroll-div > div {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        /* object-fit: fill; */
        /* background-color: red; */
    }
    /* .reel-middle-sec > div > div {
        background-color: red;
    } */
    .reel-middle-sec > div > div > video {
        min-width: 200px;
        width: 100%;
        border-radius: 0px;
    }
    .reel-threedot {
        border-radius: 0;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
        top: -300px;
        right: 40px;
        z-index: 10;
    }
    
}