.accounts-right-sec {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    scrollbar-width: none;
}
.acc-midl-flex {
    display: flex;
}

/* Right sec css */ 
.user-profile-img {
    height: 400px;
    display: flex;
    justify-content: center;
    position: relative;
}
.account-cover-img {
    height: 100%;
    width: 90%;
    object-fit: cover;
    border-bottom-right-radius: 150px;
    border-bottom-left-radius: 150px;
    position: relative;
}
.user-profile-img > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40px;
}
.user-profile-img > div > img {
    height: 120px;
    width: 120px;
    object-fit: cover;
    border: 2px solid white;
    border-radius: 20px;
    margin-bottom: 10px;
}
.user-profile-img > div > p {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    color: white;
    -webkit-text-stroke: 1px black;
}

.user-profile-img > button {
    position: absolute;
    bottom: -20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.followers-box {
    background-color: #38424b87;
    display: flex;
    justify-content: space-between;
    /* gap: 50px; */
    height: fit-content;
    width: 140%;
    padding: 8px 18px;
    border: 1px solid rgb(175, 175, 175);
    border-radius: 20px;
    margin-top: 20px;

    backdrop-filter: blur(4px)
}

.followers-box > div {
    text-align: center;
}
.followers-box > div > button {
    border: none;
    background-color: transparent;
    color: white;
    letter-spacing: 2px;
    font-size: 18px;
    cursor: pointer;
}
.followers-box > div > p {
    margin: 0;
    margin-top: 8px;
    color: #BDFA3C;
    font-size: 18px;
    cursor: pointer;
}

.account-about-sec {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 60%;
}
.acc-about-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.acc-about-description > div {
    width: 75%;
}
.acc-about-description > div > h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 5px;
}
.acc-about-description > div > p {
    margin: 0;
    font-size: 17px;
}
.acc-about-description > button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #BDFA3C;
    font-size: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}



.accounts-abouts {
    display: flex;
    width: fit-content;
}

.accounts-friends {
    height: fit-content;
    position: relative;
    margin-top: 15px;
    width: 40%;
}
.accounts-friends > div:nth-child(1) {
    display: flex;
}
.accounts-friends > div > p {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}
.accounts-friends > div:nth-child(1) > button {
    background-color: transparent;
    border: none;
    margin-left: auto;
    font-size: 15px;
    cursor: pointer;
}

.accounts-friends > div:nth-child(2) {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.accounts-friends > div > img {
    height: 60px;
    width: 60px;
    border-radius: 40px;
    position: relative;
}
/* .img-1 {
    z-index: 5;
} */
.img-2 {
    right: 10%;
    /* z-index: 4; */
}
.img-3 {
    right: 20%;
    /* z-index: 3; */
}
.img-4 {
    right: 30%;
    /* z-index: 2; */
} 
.img-5 {
    right: 40%;
}
.accounts-friends > div:nth-child(2) > button {
    position: relative;
    right: 10%;
    background-color: transparent;
    border: none;
    cursor: pointer;
}


.account-highlight {
    display: flex;
    gap: 80px;
    /* justify-content: space-between; */
    margin-top: 18px;
}
.account-highlight > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.account-highlight > div > img {
    height: 60px;
    width: 60px;
    border: 1px solid #BDFA3C;
    border-radius: 50%;
    cursor: pointer;
}
.account-highlight > div > p {
    margin: 0;
    margin-top: 10px;
}


.acc-edit-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    margin-top: 20px;
}
.acc-edit-sec > div:nth-child(1) {
    width: 72%;
    /* justify-content: center; */
    display: flex;
    gap: 10px;
    padding-right: 10px;
}
.acc-edit-sec > div:nth-child(1) > a:nth-child(1) {
    width: 100%;
    display: flex;
    justify-content: center;
}

.acc-edit-sec > div:nth-child(1) > a:nth-child(1) > button {
    /* justify-self: center; */
    /* width: 65%; */
    width: 100%;
    padding: 7px 0px;
    font-size: 15px;
    /* margin-bottom: 10px; */
    border-radius: 10px;
    border: none;
    cursor: pointer;
    background-color: #BDFA3C;
    font-weight: 600;
}

.acc-edit-sec > div:nth-child(1) > button {
    padding: 7px 7px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    background-color: #BDFA3C;
}
.acc-edit-sec > div:nth-child(1) > button > img {
    width: 20px;
    height: 20px;
}

/* .acc-edit-sec  > button:nth-child(1) {
    background-color: #444853;
    color: #BDFA3C;
    font-weight: 600;
} */

/* .acc-edit-sec > a:nth-child(1) > button {
    background-color: #BDFA3C;
    font-weight: 600;
} */
.acc-edit-sec > a:nth-child(1) > button:hover {
    background-color: #befa3cd0;
}
.acc-edit-sec > a:nth-child(1) > button:active {
    background-color: #befa3cac;
}

.lock-switch {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 70%;
}
.lock-switch > p {
    margin: 10px 0px;
    font-size: 15px;
    font-weight: 500;
    /* color: black; */
    font-weight: 700;
}

/* Switch */

/* switch */

.create-post {
    display: flex;
    gap: 45px;
    /* background-color: #38424B; */
    /* width: 50%; */
    height: fit-content;
    padding: 20px;
    border-radius: 20px;
}
.create-post > button, .create-post > a > button  {
    display: flex;
    width: 100px;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    padding: 10px 5px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.create-post > button:hover, .create-post > a > button:hover {
    background-color: rgba(128, 128, 128, 0.737);
}
.create-post > button > img, .create-post > a > button > img {
    /* margin-bottom: 5px; */
    /* width: 40px; */
    height: 35px;
}
.create-post > button > p, .create-post > a > button > p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    text-wrap: nowrap;
    margin: 12px 0px 0px 0px;
    /* margin-left: 10px; */
    /* background-color: transparent;
    border: none;
    cursor: pointer; */
}
.create-post > hr {
    border: 1px solid white;
    border-radius: 2px;
    margin: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}


/* .accounts-posts {
    width: 58rem;
} */
.twigs-posts-btn {
    margin-top: 15px;
    margin-bottom: 20px;
}
.twigs-posts-btn > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 200px;
    margin-left: 20px;
    margin-right: 20px;
}
.twigs-posts-btn > div > button {
    text-align: center;
    font-size: 25px;
    width: 10%;
    /* padding: 0px 0; */
    background-color: transparent;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s color 0.3s;
}
.twigs-posts-btn > div > button:hover {
    transition: background-color 0.3s color 0.3s;
    background-color: #befa3cd0;
    color: black;
    border: none;
    border-radius: 20px;
    opacity: 0.85;
}
.twigs-posts-btn > div > .option_active {
    transition: background-color 0.3s color 0.3s;
    background-color: #befa3cd0;
    color: black;
    border: none;
    border-radius: 20px;
}

.twigs-posts-img {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    /* align-items: center; */
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    place-items: center; 
    row-gap: 30px;*/
}

.acc-bg-blur {
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: auto;
    background-color: #6c6c6ca5;
    display: flex;
    justify-content: center;
    z-index: 9;
    
    height: 100%;
    width: 100%;
    backdrop-filter: blur(3px);
}

.acc-post-box {
    position: absolute;
    z-index: 10;
    height: 600px;
    width: 700px;
    /* background-color: #38424B; */
    background-color: #14283a;
    border-radius: 40px;
    box-shadow: 1px 4px 15px 7px rgba(0, 0, 0, 0.44);

    /* test */
    overflow: scroll;
    scrollbar-width: none;
}
.edit-post-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 40px;
    margin-top: 20px;
}
.edit-post-button > button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 25px;
}
.edit-post-button > button > img {
    height: 100%;
}
.edit-post-button > p {
    margin: 0;
    color: white;
    font-size: 20px;
    margin-left: 20px;
}

.add-photo {
    display: flex;
    height: 100vh;
    width: 100%;
}
.add-photo > div:nth-child(1) {
    height: 100%;
    justify-content: center;
    width: 20%;
    display: flex;
    flex-direction: column;
    margin-left: 35px;
}
.add-photo > div:nth-child(1) > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
    margin-bottom: 20px;
    padding: 10px;
    cursor: pointer;
}
.add-photo > div:nth-child(1) > button:hover{
    background-color: #BDFA3C;
    border-radius: 30px;
    color: black;
}
.add-photo > div:nth-child(1) > button:hover > img {
    filter: invert();
}
.add-photo > div:nth-child(1) > button:focus{
    background-color: #BDFA3C;
    border-radius: 30px;
    color: black;
}
.add-photo > div:nth-child(1) > button:focus > img {
    filter: invert();
}
.add-photo > div:nth-child(1) > button > img {
    margin-right: 10px;
    height: 25px;
    width: 25px;
}

.add-photo > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
}
.add-photo > div:nth-child(2) > img {
    /* height: 400px; */
    width: 90%;
}

.add-photo > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 25%;
}
.add-photo > div:nth-child(3) > img {
    height: 80px;
}
.confirm-btn {
    display: flex;
    justify-content: center;
    width: 100%;
}
.confirm-btn > button {
    font-size: 25px;
    font-weight: 500;
    padding: 5px 15px;
    background-color: #BDFA3C;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: opacity 0.15s;
}
.confirm-btn > button:hover {
    opacity: 0.8;
}

.select-photo {
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.select-photo > p {
    font-size: 25px;
    color: white;
}
.select-photo > img {
    height: 12rem;
    margin-top: 70px;
    margin-bottom: 70px;
}
.select-photo > button {
    font-size: 20px;
    font-weight: 500;
    padding: 5px 10px;
    margin-bottom: 20px;
    background-color: #BDFA3C;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.select-photo > video {
    height: 350px;
    
}

.final-edit {
    display: flex;
    height: 90%;
    width: 100%;
}
.final-edit > div:nth-child(1) {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.final-img-show {
    width: 90%;
    display: flex;
    overflow: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
}
.final-img-show > img {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
    scroll-snap-align: center;
}

.final-edit > div:nth-child(1) > img {
    /* height: 400px; */
    width: 300px;
    margin-top: 30px;
    margin-bottom: 20px;
    /* aspect-ratio: 1/1.5; */
}
.final-edit > div:nth-child(1) > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.final-edit > hr {
    margin: 0;
    margin-right: 20px;
    height: 92%;
    border: 1px solid black;
    border-radius: 2px;
}
.final-edit > div:nth-child(3) {
    height: 100%;
    width: 45%;
}
.final-edit > div:nth-child(3) > div:nth-child(1) {
    width: 100%;
    display: flex;
    align-items: center;
}
.final-edit > div:nth-child(3) > div:nth-child(1) > p {
    color: white;
    font-size: 20px;
}
.final-edit > div:nth-child(3) > div:nth-child(1) > img {
    border-radius: 50%;
    margin-right: 20px;
    height: 40px;
    width: 40px;
}
.final-edit > div:nth-child(3) > div:nth-child(1) > button {
    margin-left: auto;
    background-color: transparent;
    border: none;
    color: #BDFA3C;
    font-size: 18px;
    cursor: pointer;
}

.final-edit > div:nth-child(3) > div:nth-child(2) {
    width: 100%;
    height: 90%;
    margin-top: 10px;
    color: white;
}
.final-edit > div:nth-child(3) > div:nth-child(2) > textarea {
    width: 95%;
    color: white;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    padding: 5px;
    outline: none;
}
.final-edit > div:nth-child(3) > div:nth-child(2) > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.twig-view-main{
    position: fixed;
    height: 100%;
    width: 85%;
    /* z-index: 1000;
    left: 50%;
    transform: translateX(-50%);
    top: 20%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.twigs-view-section {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.twig-view{
    /* width: max-content;
    height: max-content; */
    /* padding: 25px; */
    /* background-color: #38424B; */
    /* justify-content: space-between; */
    /* z-index: 10; */
    min-width: 70vw;
    height: 95vh;
    /* position: absolute; */
    background-color: #14283a;
    border-radius: 25px;
    box-shadow: 1px 4px 15px 7px rgba(0, 0, 0, 0.44);
    display: flex;
    gap: 10px;
    color: white;
    overflow: hidden;
}
.twigs-caption {
    width: 100%;    
}
.twigs-caption > section { 
    display: flex;
    gap: 10px;
    margin-top: 15px;
    margin-left: 70px;    
}
.twigs-caption > section > button {
    display: flex;
    align-items: center;
    gap: 10px;
}
.twigs-caption > p {
    margin-top: 10px;
    margin-left: 70px;    
    min-height: 15px;

}
/* .twigs-view-blur {
    display: flex;
    justify-content: center;
    align-items: center;
} */


.twig-image-size {
    height: 100%;
    /* display: flex; */
    /* gap: 10px; */
    /* object-fit: contain; */
    /* min-width: fit-content; */
    /* max-width: 550px; */
    width: 100%;
    max-width: 550px;
    /* background-color: black; */
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;

    position: relative;
    /* overflow: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    cursor: pointer; */
}
/* .twig-image-size > img{
    scroll-snap-align: center;
    height: 100%;
    object-fit: contain;
    max-width: 100%; */


    /* max-width: 500px; */
    /* min-height: 200px;
    max-height: 500px;
    max-width: 70%; */
    /* aspect-ratio: 3/3; */
/* } */

.twig-image-size > button {
    z-index: 20;
    position: absolute;
    top: 50%;
    bottom: 50%;
    transition: opacity 0.5s;
    opacity: 1;
    min-width: 30px;
    min-height: 30px;
}
.twig-image-size > button >img {
    height: 30px;
}
.twig-image-size > button:nth-child(1) {
    /* left: 230px; */
    left: 10px;
}
.twig-image-size > button:last-child {
    /* right: 785px; */
    right: 10px;
}
.twig-image-size:hover > button {
    transition: opacity 0.5s;
    opacity: 1;
}


.post_view_img_scroll {
    height: 100%;
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 550px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;

    overflow: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    cursor: pointer;
}
.post_view_img_scroll >img {
    scroll-snap-align: center;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
}


.twig-view > button {
    height: 40px;
    width: 40px;
    background-color: transparent;
    border: none;
}
.twig-view > button > img {
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.twig-details {
    display: flex;
    align-items: center;
    gap: 20px;
}
.twig-details > img, .twig-details > a > img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    clip-path: circle();
}
.twig-details > div > h2, .twig-details > div > a > h2 {
    font-size: 20px;
}
.twig-details > div > p {
    font-size: 12px;
}
.twigs-three-dot {
    position: relative;
}
.twigs-three-dot > button > img {
    filter: invert(100%);
}
.twigs-setting-open {
    /* right: 16%; */
    background-color: #8a8a8ab3;
    z-index: 20;
}

.twigs-comments {
    height: 80%;
    position: relative;
}
.twigs-comment_text {
    width: 95%;
    height: 90%;
    overflow: scroll;
    scrollbar-width: none;
}
.twigs-reply-comment-text > div > p{
    margin-right: 20px;
}
.twigs-send-msg {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0px;
}
.twigs-send-msg > div {
    width: 95%;
    display: flex;
    align-items: center;
    background-color: #C4C2C2;
    border-radius: 15px;
    padding: 5px;
}
.twigs-send-msg > div > input {
    width: 100%;
    outline: none;
    background-color: transparent;
    border: none;
}
.twigs-send-msg > div > img {
    height: 25px;
    width: 25px;
    margin-left: 5px;
    margin-right: 10px;
    cursor: pointer;
}
.twigs-send-msg > section{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #A7DD35;
    height: 40px;
    width: 40px;
    border-radius: 50%; 
    margin-left: 20px;
    cursor: pointer;
}
.twigs-send-msg > section > img {
    height: 20px;
    margin-left: 3px;
}

/* Tales Css */
.tales-main {
    display: flex;
}
.your-tales {
    width: 25%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
}
.your-tales > div:nth-child(1) {
    margin-top: 15px;
    margin-left: 10px;
}
.your-tales > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.your-tales > div:nth-child(2) > div {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.your-tales > div:nth-child(2) > p {
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 8px;
}
.your-tales > div:nth-child(2) > div > img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
}
.your-tales > div:nth-child(2) > div > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
/* .your-tales > div:nth-child(2) > div > hr {
    border: 1px solid black;
    width: 100%;
    margin: 0;
} */

.edit-tales {
    width: 100%;
    height: 100vh;
    /* background: linear-gradient(241deg, #A555EC 7.41%, rgba(208, 156, 250, 0.87) 33.56%, rgba(243, 204, 255, 0.81) 56.9%, #F4F4B9 83.77%);; */
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    overflow: hidden;
}
.tales-option {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    gap: 50px;
}
.tales-option > button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #38424B; */
    width: 250px;
    height: 300px;
    border: 3px solid #BDFA3C;
    border-radius: 20px;
    font-size: 25px;
    color: white;
    cursor: pointer;
}
.tales-option > button > img {
    height: 80px;
    margin-bottom: 40px;
}

.bg-color-btn {
    display: flex;
    justify-content: center;
    gap: 20px;
}
.bg-color-btn > button:nth-child(1) {
    height: 40px;
    width: 40px;
    border: 1px solid black;
    border-radius: 50%;
    background: linear-gradient(235deg, #3E001F 0.81%, #982176 40.02%, #D83C79 69.05%, #FFE5AD 97.09%);
}
.bg-color-btn > button:nth-child(2) {
    height: 40px;
    width: 40px;
    border: 1px solid black;
    border-radius: 50%;
    background: linear-gradient(241deg, #A555EC 7.41%, rgba(208, 156, 250, 0.87) 33.56%, rgba(243, 204, 255, 0.81) 56.9%, #F4F4B9 83.77%);
}
.bg-color-btn > button:nth-child(3) {
    height: 40px;
    width: 40px;
    border: 1px solid black;
    border-radius: 50%;
    background: linear-gradient(241deg, #6499E9 7.41%, #9EDDFF 36.42%, #A6F6FF 61.37%, #BEFFF7 80.15%);
}
.bg-color-btn > button:nth-child(4) {
    height: 40px;
    width: 40px;
    border: 1px solid black;
    border-radius: 50%;
    background: linear-gradient(241deg, #900C3F 7.41%, #C70039 33.56%, #F94C10 56.9%, #F8DE22 83.77%);
}
.bg-color-btn > button:nth-child(5) {
    height: 40px;
    width: 40px;
    border: 1px solid black;
    border-radius: 50%;
    background: linear-gradient(236deg, #FFAD84 0.8%, rgba(255, 196, 126, 0.83) 37.64%, #FFE382 72.03%, #FFF78A 94.08%);
}

/*  */
.preview-tales {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.preview-tales > section:nth-child(1) {
    display: flex;
    flex-direction: column;
    height: 80%;
    padding: 0px 50px 0px 0px;
}
.preview-tales > section:nth-child(1) > p{
    font-size: 25px;
    margin: 0;
    margin-bottom: 20px;
}
.preview-tales > section:nth-child(1) > img {
    height: 550px;
    width: 430px;
    border-radius: 30px;
    border: 2px solid black;
}
.preview-tales > hr {
    height: 85%;
    border: 1px solid black;
    margin: 0;
}

.tales-views {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 80%;
}
.tales-views > p {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 5px;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 30px;
}
.tales-views > div {
    display: flex;
    align-items: center;
    width: 55%;
}
.tales-views > div > img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 20px;
}
.tales-views > div > p:nth-child(2) {
    margin:0;
    margin-right: auto;
    font-size: 20px;
    font-weight: 400;
}
.tales-views > div > p:nth-child(3) {
    margin:0;
    font-size: 13px;
}

.edit-story {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-around;
    width: 100%;
    height: 100%;
}
.edit-story > p {
    margin: 0;
    font-size: 35px;
    font-weight: 400;
    letter-spacing: 5px;
    color: white;
    align-self: center;
}
.edit-story > div {
    display: flex;
    justify-content: center;
}
.edit-story > div > section:nth-child(1) {
    display: flex;
    flex-direction: column;
    /* height: 80%; */
    padding: 0px 50px 0px 0px;
}
.edit-story > div > section:nth-child(1) > p{
    font-size: 25px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 20px;
    letter-spacing: 5px;
}
.edit-story > div > section:nth-child(1) > div {
    display: flex;
    justify-content: center;
    height: 550px;
    width: 430px;
    background-color: rgba(126, 126, 126, 0.333);
    border-radius: 30px;
    border: 2px solid black;
}
.edit-story > div > section:nth-child(1) > div >  img {
    /* height: 100%; */
    max-height: 100%;
    align-self: center;
    width: 100%;
    border-radius: 28px;
}

.edit-story-field {
    width: 50%;
}
.edit-story-field > hr {
    border: 1px solid  white;
    margin: 15px 0px;
}
.edit-story-field > div:nth-child(1) > div {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
.edit-story-field > div:nth-child(1) > div > p {
    font-size: 20px;
    color: white;
    letter-spacing: 2px;
}
.edit-story-field > div:nth-child(1) > div > img {
    height: 20px;
}
/* .edit-story-field > div:nth-child(1) > textarea {
    width: 85%;
    display: block;
    margin-left: 50px;
    outline: none;
    border: 1px solid black;
    border-radius: 10px;
    max-height: 100px;
    font-size: 20px;
    text-wrap: nowrap;
} */
.edit-story-field > div:nth-child(1) > textarea {
    resize: none;
    width: 80%;
    margin-left: 60px;
    outline: none;
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0.438);
    border-radius: 10px;
    padding: 10px;
    /* max-height: 100px; */
    font-size: 20px;
}
.edit-story-field > div:nth-child(1) > textarea::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.edit-story-field > div:nth-child(3) > p, .edit-story-field > div:nth-child(5) > p {
    font-size: 20px;
    color: white;
    letter-spacing: 2px;
    margin-bottom: 10px;
}
.edit-story-field > div:nth-child(3) > div, .edit-story-field > div:nth-child(5) > div {
    /* background-color: rgba(167, 167, 167, 0.438); */
    width: 80%;
    height: 100px;
    margin-left: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: auto;
    padding: 10px;
    border: 1px solid #BDFA3C;
    border-radius: 10px;
}
.edit-story-field > div:nth-child(3) > div > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.4s;
}
.edit-story-field > div:nth-child(3) > div > button:hover {
    background-color: rgba(180, 180, 180, 0.779);
    border: none;
    cursor: pointer;
}

.edit-story-field > div:nth-child(5) > div > button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid black;
    background-color: red;
    margin: auto;
    cursor: pointer;
}

.edit-story-field > div:nth-child(6) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-top: 50px;
}
.edit-story-field > div:nth-child(6) > button {
    background-color: #BDFA3C;
    border: 1px solid #BCBBBB;
    border-radius: 10px;
    padding: 10px 25px;
    font-size: 20px;
    cursor: pointer;
}
.acc-twig-post > section{
    margin-top: 15px;
}
.acc-twig-post > p {
    margin-top: 15px;
}


.acc-mob-scroll {
    overflow: scroll;
}
.acc-mob-name {
    display: flex;
    padding: 15px 8px;
}
.acc-mob-name > img {
    width: 55px;
    height: 55px;
    border-radius: 15px;
    margin: 0px 15px 0px 0px;
}
.acc-mob-name > div {
    margin-right: auto;
}
.mob-post-img {
    display: flex;
    gap: 10px;
    overflow: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    padding: 0px 8px;
}
.mob-post-img > img{
    scroll-snap-align: center;
    min-width: 100%;
    height: auto;
    object-fit: cover;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
}
.mob-caption {
    padding: 20px 8px 10px 8px;
}
.mob-caption > p {
    font-size: 18px;
}
.mob-action-btn {
    padding: 0px 8px;
}
.mob-cmnt {
    background-color: #14283a;
    position: absolute;
    height: 100vh;
    border-radius: 0px;
}

.mobile-ham-display {
    display: none;
    /* transform: scale(0.9); */
}
.mobile-ham {
    position: absolute;
    z-index: 5;
    right: 10px;
    top: 10px;
    /* transform: rotate(45deg); */
}
.mobile-ham > img {
    transition: transform 0.3s ease;
    width: 40px;
    height: 40px;
    /* transform: scale(0.9); */
}


.mobile-ham-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    position: absolute;
    z-index: 5;
    top: 60px;
    right: 17px;
}
.mobile-ham-options > button > img, .mobile-ham-options > a > button > img {
    height: 25px;
    width: 25px;
}

.saved-post-main {
    overflow: scroll;
    scrollbar-width: none;
}
.saved-twigs-top {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
}
.saved-twigs-top > p {
    font-size: 25px;
    margin-right: auto;
}
.saved-twigs-top > button {
    margin-right: 10px;
}

.crop-style {
    height: 200px;
}

.post-box-selected-img {
    /* position: relative; */
    display: flex;
    align-items: center;
    /* width: 100%; */
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
}

.post-box-twig-img{
       /* position: relative; */
       background-color: black;
       display: flex;
       gap: 5px;
       align-items: center;
       /* justify-content: center; */
       /* width: 100%; */
       /* margin-top: 20px; */
       margin-bottom: 20px;
       overflow: scroll;
       scrollbar-width: none;
       scroll-snap-type: x mandatory; 
}

/* .post-box-twig-img > img{ */
    /* height: fit-content; Good Looks for carausol*/

    /* height: 100%; */
    /* max-width: 550px; */
    
    /* object-fit: contain; */
    /* background-color: black; */
    /* border-top-left-radius: 25px;
    border-bottom-left-radius: 25px; */
/* } */


.post-box-twig-img > button {
    position: absolute;
    z-index: 1;
    outline: none;
    clip-path: circle();
    backdrop-filter: blur(10px);
}
.post-box-twig-img > button > img, .post-box-twig-img > button {
    height: 30px;
    width: 30px;
}
.post-box-twig-img > button:nth-child(1) {
    left: 15px;
}
.post-box-twig-img > button:last-child {
    left: 500px;
}
.post_box_video {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.post_box_video > video{
    height: 100%;
}


.post-box-selected-img > img, .post-box-selected-img > video {
    height: 350px;
    max-height: 400px;
    min-width: 100%;
    object-fit: scale-down;
    scroll-snap-align: center;
}

.post-box-selected-img > button {
    position: absolute;
    z-index: 1;
    outline: none;
    clip-path: circle();
    backdrop-filter: blur(10px);
}
.post-box-selected-img > button > img, .post-box-selected-img > button {
    height: 25px;
    width: 25px;
}
.post-box-selected-img > button:nth-child(1) {
    left: 15px;
}
.post-box-selected-img > button:last-child {
    right: 15px;
}


.crop-post-scroll {
    display: flex;
    align-items: center;
    width: 100%;
    height: 75%;
    overflow: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    margin-top: 15px;
    /* margin-bottom: 20px; */
}
.crop-post-scroll > div {
    min-width: 700px;
    height: 100%;
    scroll-snap-align: center;
}
.scroll-btn {
    position: absolute;
    z-index: 100;
    outline: none;
    clip-path: circle();
    backdrop-filter: blur(10px);
}
.scroll-btn > img, .scroll-btn > button {
    height: 25px;
    width: 25px;
}
.crop-post-scroll > button:nth-child(1) {
    left: 15px;
}
.crop-post-scroll > button:last-child {
    right: 15px;
}

.takes_acc_view > div > video, .takes_acc_view > a > div > video {
    height: 450px !important;
    width: 300px;
    aspect-ratio: 3/3;
    object-fit: cover;
    border-radius: 20px;
}


@media screen and (min-width: 320px) and (max-width: 570px) {
    .account-cover-img {
        height: 350px;
        width: 100%;
        border-radius: 0px;
    }
    .user-profile-img > div {
        width: 100%;
    }
    .user-profile-img > div > p {
        font-size: 18px;
    }
    .followers-box {
        width: 80%;
    }
    .followers-box > div > button {
        font-size: 15px;
    }
    .followers-box > div > p {
        font-size: 15px;
    }
    .acc-midl-flex {
        flex-direction: column;
    }
    .account-about-sec {
        width: 100%;
        padding: 0px 10px;
    }
    .acc-about-description > div {
        width: 100%;
    }
    .acc-about-description > div > h2  {
        font-size: 20px;
    }
    .acc-about-description > div > p {
        font-size: 13px;
    }
    /* .acc-about-description > button {
        display: none;
    } */
    .acc-edit-sec {
        width: 100%;
    }

    .accounts-friends {
        width: 70%;
    }
    .accounts-friends > div:nth-child(1) > p {
        font-size: 18px;
    }
    .accounts-friends > div:nth-child(1) > button {
        font-size: 13px;
    }
    .img-2 {
        right: 15%;
    }
    .img-3 {
        right: 30%;
    }
    .img-4 {
        right: 45%;
    } 
    .img-5 {
        right: 60%;
    }
    .accounts-friends > div:nth-child(2) > button {
        right: 50%;
    }
    
    .create-post {
        display: none;
        /* width: 100%;
        gap: 0px;
        justify-content: space-between; */
    }
    .twigs-posts-btn > div {
        gap:0px;
        justify-content: space-around;
    }
    .twigs-posts-btn > div > button {
        width: 30%;
        font-size: 20px;
    }

    .acc-feeds-sec {
        gap: 0px;
        width: 100%;
        flex-wrap: wrap;
        padding: 0px 5px;
        justify-content: space-between;
    }

    .account-highlight {
        gap: 25px;
        overflow: scroll;
        scrollbar-width: none;
    }
    .account-highlight > div {
        min-width: 60px;
    }
    .account-highlight > div > img {
        height: 50px;
        width: 50px;
    }
    .account-highlight > div > p {
        font-size: 13px;
    }

    /* Post Pop up */
    .twig-view-main {
        display: none;
    }
    
    .mobile-ham-display {
        display: contents;
    }

    .acc-bg-blur {
        height: 100vh;
        width: 100vw;
    }
    .acc-post-box {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        scrollbar-width: 0px;
    }

    .add-photo {
        flex-direction: column;
        margin-bottom: 15px;
    }
    .add-photo > div:nth-child(1) {
        width: 100%;
        height: 60px;
        flex-direction: row;
        margin-left: 0px;
        margin-top: 15px;
    }
    .add-photo > div:nth-child(1) > button {
        font-size: 15px;
    }
    .add-photo > div:nth-child(2) {
        height: fit-content;
        /* max-height: 100px; */
        width: 100%;
        margin-top: 20px;
    }
    .add-photo > div:nth-child(2) > img{
        /* max-height: 200px; */
        aspect-ratio: 1/1;
        object-fit: cover;
    }
    .add-photo > div:nth-child(3) {
        flex-direction: row;
        justify-content: left;
        /* height: fit-content; */
        width: 95%;
        gap: 20px;
        /* padding: 0px 10px; */
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        overflow: scroll;
    }
    .add-photo > div:nth-child(3) > img {
        aspect-ratio: 2/1;
        object-fit: cover;
        height: 70px;
    }

    .final-edit {
        flex-direction: column-reverse;
        overflow: scroll;
    }
    .final-edit > div:nth-child(1) {
        width: 100%;
        position: relative;
    }
    .final-edit > div:nth-child(1) > img {
        aspect-ratio: 1/1;
        object-fit: cover;
    }
    .final-edit > hr {
        display: none;
    }
    .final-edit > div:nth-child(3) {
        padding: 0px 10px;
        width: 100%;
        margin-top: 20px;
    }
    .final-edit > div:nth-child(1) > button {
        position: absolute;
        bottom: 30px;
        right: 50px;
    }
    .saved-twigs-top > a > button {
        height: 15px;
        width: 15px;
    }
    .takes_acc_view {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        place-items: center;
        /* grid-template-rows: repeat(5, 1fr); */
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }
    .takes_acc_view > div > video, .takes_acc_view > a > div > video {
        height: 200px !important;
    }
}