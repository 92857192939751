.idf-heading {
    font-size: 2.2rem;
    margin-bottom: 0;
}
.idf-heading-base {
    font-size: 1rem;
    margin-bottom: 80px;
}
.idf-sub-heading {
    font-weight: 500;
    font-size: 1rem;
}

.gender-button > hr{
    margin-top: auto;
    margin-bottom: auto;
    height: 40px;
    border:1px solid rgb(189, 250, 60);
    background-color: rgb(189, 250, 60);
}

.gender-button {
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 20px;
    display: inline-flex;
    border: 2px solid rgb(189, 250, 60);
    border-radius: 10px;
}
.gender-button > button {
    border: none;
    /* background-color: white; */
    font-size: 20px;
    font-family: 'Ubuntu', sans-serif;
    margin: 0px;
    border-radius: 8px;
    padding: 10px 20px;
    transition: opacity 0.15s;
}
.gender-button > button:hover {
    background-color: rgb(189, 250, 60);
    color: black;
}
/* .gender-button > button:focus {
    
} */
 .gender-button-focus {
    background-color: rgb(189, 250, 60);
    color: black;
 }

.age-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 40px; */
    margin-top: 8px;
    margin-bottom: 50px;
}
.age-button input {
    width: 75%;
    margin-right: 5px;
    text-align: center;
    padding: 10px 20px;
    outline-color: rgb(189, 250, 60);
    border: 2px solid rgb(189, 250, 60);
    border-radius: 8px;
    background-color: transparent;
}
.age-button input::-webkit-outer-spin-button,
.age-button input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.age-button input[type=number] {
    -moz-appearance: textfield;
  }

.describe-yourself {
    /* border: none; */
    font-size: 17px;
    width: 330px;
    height: 30px;
    /* border-bottom: 2px solid black; */
    background-color: transparent;
}
.describe-yourself:active {
    outline: none;
}

.describe-yourself:focus {
    outline: none;
}

/* .continue-button{
    text-align: center;
    margin-top: 40px
} */
/* .continue-button button {
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 20px;
    padding: 10px 80px;

    border: 1px solid rgb(188,187,187);
    border-radius: 10px;
    background-color: rgb(189, 250, 60);
    transition: opacity 0.15s;
} */
.continue-button button:hover{
    opacity: 0.8;
}
.continue-button button:active {
    opacity: 1;
}

@media screen and (min-width: 320px) and (max-width: 570px) {
    .login-header > p {
        margin-top: 10px;
        font-size: 25px;
    }
    .pg2-user-input {
        margin-top: 40px;
    }
}