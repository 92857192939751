/* .login-header {
    width: 500px;
    margin: auto;
    position: relative;
    top: 20px;
} */

/* .logo {
    text-align: center;
}
#logo-twigyy {
    height: 40px;
    width: 150px;
    display: inline-block;
} */

/* .login-header > p {
    text-align: center;
    font-family: 'Ubuntu', sans-serif;
    font-size: 18px;
    margin-top: 50px;
} */

.pg3-user-input {
    margin-bottom: 50px;
} 
.password-input {
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    height: 36px;
    width: 300px;
    text-align: center;
    font-size: 16px;
    border: 3px solid rgb(189, 250, 60);
    border-radius: 20px;
    margin-bottom: 20px;
    outline: none;
}
.confirm-password-input {
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    height: 36px;
    width: 300px;
    text-align: center;
    font-size: 16px;
    border: 3px solid rgb(189, 250, 60);
    border-radius: 20px;
    outline: none;
    background-color: transparent;
}
.confirm-password-input[type='password']:focus {
    --tw-ring-color: #ffffff00;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.confirm-password-input:focus{
    outline: none;
    border-color: rgb(189, 250, 60);
}


/* .down-login-with {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 90px;
}
.login-with {
    font-family: 'Ubuntu', sans-serif;
    font-size: 12px;
    display:inline;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
}
hr {
    margin: 0;
    margin-bottom: 2px;
    display:inline-block;
    width: 150px;
    height: 1px;
    border: none;
    background-color: black;
} */



/* .logo-google {
    height: 40px;
    width: 40px;
}

.continue-button{
    text-align: center;
    margin-top: 40px
}
button {
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 20px;
    padding: 10px 40px;

    border: 1px solid rgb(188,187,187);
    border-radius: 10px;
    background-color: rgb(189, 250, 60);
    transition: opacity 0.5s;
}
button:hover{
    opacity: 0.8;
}
button:active {
    opacity: 0.6;
}

.continue-button > p {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 30px;
} */
.check-msg {
    margin-bottom: 350px;  
  }
  @media screen and (min-width: 320px) and (max-width: 570px) {
    .check-msg {
        margin-top: 50px;
        margin-bottom: 0px;
    }
}