.acc-type-main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 50px; */
    position: relative;
}
.acc-type-main > p {
    font-size: 25px;
    /* font-weight: 500; */
    margin-top: 30px;
}
.select-type-acc {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 20px;
    margin-top: 50px;
}
.select-type-acc > button {
    border: 2px solid #BDFA3C;
    padding: 3px 0px;
    border-radius: 20px;
    transition: background-color 0.25s;
}
.select-type-acc > button:hover, .select-type-acc > button:focus  {
    background-color: #BDFA3C;
}
.acc-type-continue-btn, .acc-type-main > a {
    width: 100%;
    position: absolute;
    bottom: 15px;
    display: flex;
    justify-content: center;
    color: black;
}
.acc-type-continue-btn > button {
    width: 15%;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 0px;
    background-color: #BDFA3C;
    border-radius: 10px;
}
.acc-type-continue-btn > button:hover {
    background-color: rgba(168, 221, 53, 0.836);
}
.acc-type-continue-btn > button:active {
    background-color: #a8dd35;
}