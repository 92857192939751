.crop-area {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    overflow: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
}
.crop-area > div {
    min-width: 100%;
    scroll-snap-align: center;
}
.add-photo {
    height: 100%;
}
.add-photo > div:nth-child(1) {
    width: 100%;
    height: 350px;
    /* align-self: center; */
    margin-left: 0px;
    margin-top: 50px;
}
.crop-continue {
    width: max-content;
    position: absolute;
    bottom: 10px;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.crop-continue > button {
    font-weight: 500;
    border-radius: 10px;
    padding: 5px 20px;
    background-color: #BDFA3C;
}
.crop-continue > button:active {
    background-color: #91c02d;
}
.reactEasyCrop_Container {
    width: 100%;
    height: 100%;
    position: relative !important;
    /* display: block !important; */
}
/* .reactEasyCrop_Container > img { */
    /* width: 100%;
    height: 100%; */
    /* margin: 0px; */
/* } */
/* .reactEasyCrop_CropArea {
    left: 0px !important;
    top: 0px !important;
    transform: none !important;
} */

.aspect-ratio-select {
    position: absolute; 
    left: 20px;
    bottom: 100px;

    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.509);
    display: inline-block;
    z-index: 100;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
}
/* .aspect-ratio-select > select {
    width: 100px;
    border-radius: 20px;
    padding: 5px 20px;
    outline: none;
    background-color: rgba(255, 255, 255, 0.4);
} */


.aspect-drop{
    display: none;
    /* position: absolute;
    background-color: red; */
    /* z-index: 101; */
}
.aspect-drop > button{
    display: flex;
    justify-content: space-around;
    padding: 5px;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;
    border-radius: 10px;
}
.aspect-drop > button > img {
    height: 15px;
}
.aspect-drop > button:hover {
    background-color: rgb(104, 104, 104);
}

.aspect-ratio-select:focus .aspect-drop, .aspect-ratio-select:hover .aspect-drop {
    display: contents;
}
@media screen and (min-width: 320px) and (max-width: 570px) {

    .reactEasyCrop_Container{
        width: 100vw;
        height: 50%;
    }
    .crop-post-scroll > div {
        min-width: 100%;
    }
    .crop-area {
        /* height: 50%; */
    }
    .reactEasyCrop_Container {
        top: 25% !important;
        /* bottom: 50% !important; */
    }
}