.brand-shopping {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.own-brand-shopping > button{
    width: 80%;
}

.brand-shopping > section {
    width: 80%;
    display: flex;
    justify-content: center;
}
.brand-shopping > section > button {
    width: 100%;
    background-color: #444853;
    border: none;
    text-wrap: wrap;
    color: white;
    font-size: 18px;
    border-radius: 10px;
    padding: 10px 40px;
    cursor: pointer;
}
.brand-shopping > section > button > span {
    display: block;
    color: rgb(142, 142, 142);
    font-size: 15px;
    letter-spacing: 2px;
}
.own-brand-main > h2 {
    font-size: 1.6rem;
    font-weight: 500;
}