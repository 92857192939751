.brand-advartisement {
    display: flex;
    gap: 50px;
    /* justify-content: space-between; */
    /* width: 100%; */
    overflow: scroll;
    scrollbar-width: none;
}
.brand-feeds-sec {
    width: 100%;
}
.brand-hlight-action {
    display: flex;
    flex-direction: column;
    /* margin-top: 20px; */
}
.brand-test-scroll{
    width: 95%;
}

.brand-sec-all {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}
.brand-advartisement > h2 {
    display: block;
}
.brand-advartisement > div {
    min-width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.brand-advartisement > div > img {
    height: 60px;
    width: 60px;
    border: 1px solid #BDFA3C;
    border-radius: 50%;
    cursor: pointer;
}
.brand-advartisement > div > p {
    margin: 0;
    margin-top: 10px;
}

.brand-action-btn {
    /* margin-right: 7%; */
    height: fit-content;
}

.s-brand-shopping {
    display: flex;
    width: 100%;
    /* flex-direction: column; */
    margin-top: 20px;
    gap: 20px;
}
.s-brand-shopping > a {
    display: flex;
    width: 100%;
    /* flex-direction: column; */
    /* margin-top: 20px; */
}
.s-brand-shopping > button, .s-brand-shopping > a > button  {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #BDFA3C;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    padding: 5px 40px;
    cursor: pointer;
}
.s-brand-shopping > a > button > img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.s-brand-about-sec {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    /* width: 60%; */
}

.broadcast-main {
    display: flex;
    align-items: center;
    margin: 20px 0px;
}

.brand-broadcast {
    width: 30%;
}
.brand-broadcast > p {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 5px;
}
.brand-broadcast > a {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 10px;
}

.today-deal {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.deals-post {
    position: relative;
    overflow: hidden;
}
.deals-post > p {
    color: white;
    font-size: 20px;
    background-color: #38424B;
    padding: 0px 20px;
    border-radius: 20px;
    top: 10px;
    left: 10px;
    position: absolute;
}
.deals-post > button {
    width: 100%;
    position: absolute;
    bottom: 0px;
    background-color: #BDFA3C;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
@media screen and (min-width: 320px) and (max-width: 570px) { 

    .broadcast-main {
        flex-direction: column;
    }
    .brand-broadcast {
        width: 95%;
        text-align: center;
    }
    .brand-broadcast > button {
        margin-left: auto;
        margin-right: auto;
    }
    .brand-broadcast > a {
        padding: 0px;
    }
    .brand-test-scroll {
        width: 100%;
    }
    .s-brand-shopping {
        flex-direction: column;
        padding: 0px 10px;
    }
    .s-brand-shopping > button, .s-brand-shopping > a > button {
        width: 100%;
    }
    .brand-advartisement {
        margin: 0px 10px;
    }
    .brand-broadcast > p {
        font-size: 20px;
    }

}