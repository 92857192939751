/* body::-webkit-scrollbar {
    display: none; 
} */
.acc-hide-mobile {
    display: none;
}

.cmnt_main {
    position: absolute;
    left: 0px;
}

.home_blur {
    position: fixed;
    top: auto;
    bottom: auto;
    background-color: #6c6c6ca5;
    display: flex;
    justify-content: center;
    z-index: 9;
    
    height: 100%;
    width: 100%;
    backdrop-filter: blur(10px); 

}
.comment_box {
    position: absolute;
    bottom: 0;
    height: 75%;
    /* width: 50%; */
    width: 40%;
    /* background-color: #38424B; */
    border-radius: 40px 40px 0px 0px;
    box-shadow: 1px 4px 15px 7px rgba(0, 0, 0, 0.44);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}
.comment_top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.comment_top > hr:nth-child(1) {
    /* margin-top: 5px; */
    width: 8%;
    height: 5px;
    border: none;
    background-color: white;
    border-radius: 10px;
}
.comment_top > hr:nth-child(3){
    margin-top: 10px;
    width: 90%;
    height: 2px;
    border: none;
    background-color: white;
    border-radius: 10px;
}
.comment_top > p {
    margin: 0;
    width: fit-content;
    color: white;
    font-size: 30px;
}

.comment_text {
    width: 90%;
    /* height: 100%; */
    margin-top: 15px;
    /* display: flex;
    align-items: center; */
}
.comment-scroll {
    width: 90%;
    overflow: scroll;
    height: 74%;
    padding: 0px 0px 20px 0px;
    scrollbar-width: none;
}


.comment_text > div:nth-child(1) {
    display: flex;
    align-items: center;
    width: 100%;
}
.comment_text > div:nth-child(1) > div > div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 10px;
}
.comment_text > div:nth-child(1) > div > div:nth-child(1) > img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}
.comment_text > div:nth-child(1) > div > div:nth-child(1) > p:nth-child(2) {
    color: white;
    /* margin-left: 10px; */
}
.comment_text > div:nth-child(1) > div > div:nth-child(1) > p:nth-child(3) {
    color: #C9C9C9;
}
.comment_text > div:nth-child(1) > div > p {
    margin: 0;
    color: white;
    margin-left: 46px;
}
.comment_text > div:nth-child(1) > div > div:nth-child(3) {
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    margin-left: 46px;
    margin-top: 10px;
}
.comment_text > div:nth-child(1) > div > div:nth-child(3) > hr {
    margin: 0;
    border: 1px solid white;
    height: 20px;
}



/* .comment_text > div:nth-child(1) > p {
    margin-left: auto;
    color: #C9C9C9;
} */

/*  */
.reply-comment-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
.reply-comment-text > div {
    display: flex;
    align-items: center;
    width: 99%;
}
.reply-comment-text > div > div > div {
    display: flex;
    align-items: center;
    gap: 10px;
}
.reply-comment-text > div > div > div:nth-child(1) > img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}
.reply-comment-text > div > div > div:nth-child(1) > p:nth-child(2) {
    color: white;
    /* margin-left: 10px; */
}
.reply-comment-text > div > div > div:nth-child(1) > p:nth-child(3) {
    color: #C9C9C9;
}
.reply-comment-text > div > div > p {
    margin: 0;
    color: white;
    margin-left: 46px;
}
.reply-comment-text > div > div > div:nth-child(3) {
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    margin-left: 46px;
    margin-top: 10px;
}
.reply-comment-text > div > div > div:nth-child(3) > hr {
    margin: 0;
    /* width: 1px; */
    border: 1px solid white;
    height: 20px;
}
/* .reply-comment-text > div > p {
    margin-left: auto;
    color: #C9C9C9;
} */
.reply_comment {
    display: flex;
    /* align-items: center; */
    width: 90%;
    margin-left: 74px;
    margin-top: 10px;
}
.reply_comment > em {
    width: 1px;
    /* height: 100%; */
    border: 1px solid white;
    margin-right: 10px;
    margin-left: 46.5px;
    /* background-color: white; */
    border-radius: 10px;
}




.home_main {
    display: flex;
    /* gap: 20px; */
    /* justify-content: space-between; */
    scrollbar-width: none;
    width: 100%;
    /* overflow: hidden; */
    /* filter: blur(5px); */
}
/* .home-scroll-off {
    position: fixed;
    /* overflow: hidden; */
    /* height: 100vh; */

/* .main>* {
    flex:1 1 0;
} */
.grey-side {
    display: flex;
    flex-direction: column;
    justify-content: start;
    /* background-color: #38424B; Main*/
    /* background-color: #0b1c1c; */
    /* background: linear-gradient(236deg, #FFAD84 0.8%, rgba(255, 196, 126, 0.83) 37.64%, #FFE382 72.03%, #FFF78A 94.08%); */
    /* position: sticky; */
    position: relative;
    /* top: 0; */
    padding: 0 20px 0 20px;
    width: 25%;
    height: 100vh;
    /* overflow-y: scroll;
    scrollbar-width: none; */
}

.grey-side-scroll {
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none;
}

.white-side { 
    /* width: 50%; */
    width: 60%;
    /* margin-top: 25px; */
    /* padding-top: 25px; */
    /* padding: 0px 50px; */
    margin: 0px auto;
    height: 100vh;
    overflow: scroll;
    scrollbar-width: none;
    background-color: transparent;
    position: relative;
    /* padding: 25px 10px; */
}

.bulletin-outer {
    width: 27%;
    
}
.red-box {
    background-color: red;
    width: 300px;
    height: 500px;
}
.bulletin-sec {
    /* width: 100%; */
    flex: content;
    background-color: transparent;
    padding: 0px 20px 0 10px;
    position: relative;
    height: 100vh;
    overflow: scroll;
    scrollbar-width: none;
}


/* Grey-side css */
.home-head {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
.home-head > img {
    height: 28px;
    /* filter: drop-shadow(5px 5px 0.8px #383838); */
    margin: 0;
    padding: 2px 2px;
    background-color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    /* margin-bottom: 25px; */
}
.home-head > input {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    padding: 2px 0;
    padding-left: 10px;
    font-weight: 400;
    font-size: 15px;
    border:none;
    /* border-radius: 50px; */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    /* text-align: center; */
    outline: none;
}
.home-head > input:focus {
    --tw-ring-color: #ffffff00;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    border-color: rgb(189, 250, 60);
}
.grp-sec > div > img, .online-sec > div > img, .home-online-sec > div > div > img, .suggestion-sec > a > div > img{
    height: 28px;
    width: 28px;
    border-radius:50%;
    object-fit: cover;
}
.home-online-sec > div > div > p:nth-child(2), .online-sec > div > p:nth-child(2) ,.suggestion-sec > a > div > p,.grp-sec > div > p {
    display: inline-block;
    font-family: 'Ubuntu',sans-serif;
    font-weight: 900;
    font-size: 13px;
    color:white;
    margin: 10px 0px;
}


.sub-heading {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 15px;
    margin: 15px 0px;
}

.grp-sec > div {
    display: flex;
    align-items: center;
    gap: 20px;
}


.home-online-sec > div > div, .online-sec > div {
    display: flex;
    align-items: center;
    gap: 20px;
}
.online-indicator {
    background-color: #11FF23;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    margin-left: auto;
}
.online-time {
    color: #bcbcbc;
    margin-left: auto;
    font-size: 13px;
}


.suggestion-sec > a > div {
    display: flex;
    align-items: center;
    gap: 20px;
}

.suggestion-sec > a > div > button {
    font-family: 'Ubuntu',sans-serif;
    background-color: white;
    font-weight: 700;
    font-size: 13px;
    padding:2px 10px;
    border:none;
    border-radius: 5px;
    margin-left: auto;
    cursor: pointer;
    transition: 0.5s background-color;
}

.more {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Ubuntu',sans-serif;
    color: #FFFFFF;
    letter-spacing: 2px;
}
.more > p, .more > button {
    margin: 0;
    font-size: 13px;
    margin-top: 15px;
}



/* white-side css */
.home-nav-bar {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    /* margin-bottom: 25px; */
}
.home-nav-icon {
    position: fixed;
    display: flex;
    justify-content: space-between;
    /* background-color: #38424B; */
    background-color: #14283a;
    /* width: 70%; */
    /* padding: 10px 10px;
    width: 30%;
    border-radius: 10px;      */
    min-width: 28%;
    padding: 10px 20px;
    border-radius: 50px;

    z-index: 8;  
}

.home-normal-icon {
    /* height: 40px; */
    padding: 7px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}
.home-normal-icon > img {
    height: 28px;
    width: 28px;
}
.home-focus-icon {
    /* height: 40px; */
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 1s, border-radius 2s;
    background-color: #B9F53B;
    border-radius: 50%;
    border: none;
    cursor: pointer;
}
.home-focus-icon > img {
    position: relative;
    right: 0.8px;
    height: 28px;
    width: 28px;
    /* margin-right: 2px; */
    filter: invert();
}


/* STORY */
.home-story-sec{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 30px;
    margin-bottom: 15px;
    /* margin-top: 85px; */
    margin-top: 25px;
    padding: 0px 5px;
    position: relative;
}
.home-story-sec > button {
    position: absolute;
    z-index: 1;
    outline: none;
    clip-path: circle();
    backdrop-filter: blur(10px);
    transition: opacity 0.3s;
    opacity: 0;
}
.home-story-sec > button > img {
    height: 25px;
    width: 25px;
    /* filter: contrast(30%); */
}
.home-story-sec > button:nth-child(1) {
    left: 80px;
}
.home-story-sec > button:last-child {
    right: 0px;
}

.home-story-sec:hover > button {
    transition: opacity 0.3s;
    opacity: 1;
}


.home-story-sec > div {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 30px;
    /* padding: 8px 5px; */
    overflow: scroll;
    scrollbar-width: none;
}
.home-story-sec > div > button > img, .home-story-sec > div > a > button > img{
    height: 55px;
    min-width: 55px;
    object-fit: fill;
    border: 2px solid rgb(189, 250, 60);
    border-radius: 15px;
}
.home-story-sec > a > section > button > img {
    height: 55px;
    min-width: 55px;
    object-fit: cover;
    border: 2px transparent;
    border-radius: 15px;
    opacity: 0.6;
    box-shadow: 2px 2px 3px 1px black;
}
.home-story-sec > div > button,.home-story-sec > div > a > button , .home-story-sec > section > button {
    display: flex;
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
}
.home-story-sec > a > section{
    position: relative;
}
.home-story-sec > a > section > img {
    height: 25px;
    width: 25px;
    position: absolute;
    bottom: -8px;
    right: -10px;
}

.home-hr{
    border: 1px solid rgb(158, 158, 158);
    border-radius: 1px;
    margin: 0;
}

.para {
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 3px;
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}



/* POST BOX */

.content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    /* padding: 0px 15px 0px 15px; */
}
.box{
    display: flex;
    flex-direction: column;
    /* width: 92%; */
    width: 65%;
    /* background-color: #dcdcdc; */
    /* box-shadow: 1px 1px 18px 2px rgb(192, 192, 192); */
    border: 1px solid #757575;
    /* border-top-left-radius: 50px;
    border-bottom-right-radius: 50px; */
    border-top-left-radius: 35px;
    border-bottom-right-radius: 35px;
    padding: 20px;
    position: relative;
    
    /* background-color: #151515;
    border: 1px solid #000000;
    box-shadow: 1px 1px 18px 2px rgb(21, 21, 21); */
}
.content-head {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.content-head > img{
    height: 55px;
    width: 55px;
    object-fit: fill;
    border-radius: 15px;
    margin-right: 15px;
}
.home-profile-img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 15px;
    margin-right: 15px;
}
.content-head > div {
    display: flex;
    flex-direction: column; 
    height: fit-content;
}
.content-head > div > p {
    margin:0;
    margin-top:5px;
    font-size: 13px;
    font-family: 'Inter',sans-serif;
}
.content-head > div > a {
    text-decoration: none;
}
.content-head > div > a > p {
    margin: 0;
    /* color: #000000; */
}
.content-head > div > p:nth-child(1) {
    font-weight: 600;
}

.content-head > section {
    margin-left:auto;
    position: relative;
}
.content-head > section button{
    background-color: transparent;
    border: none;
    /* margin-left:auto; */
    cursor: pointer;
}
.content-head > section button > img {
    width: 35px;
}

.content-img {
    display: flex;
    /* justify-content: center; */
    gap: 10px;
    margin-bottom: 15px;
    max-height: 800px;
    overflow: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    cursor: pointer;
    /* scroll-snap-align: center; */
}

.content-img > a {
    scroll-snap-align: center;
    min-width: 100%;
    height: auto;
}

.content-img > a > img, .content-img > a > video{
    scroll-snap-align: center;
    min-width: 100%;
    /* height: auto; */
    height: 100%;
    object-fit: cover;
    /* border-top-left-radius: 50px;
    border-bottom-right-radius: 50px; */
    border-top-left-radius: 35px;
    border-bottom-right-radius: 35px;
    /* aspect-ratio: 1/1; */
    /* box-shadow: 10px 10px 6px rgb(192, 192, 192); */
}
.content-img  > button {
    z-index: 20;
    position: absolute;
    top: 45%;
    /* bottom: 50%; */
    transition: opacity 0.5s;
    opacity: 0;
}
.content-img > button > img {
    height: 30px;
}
.content-img > button:first-child {
    left: 10px;
}
.content-img > button:last-child {
    right: 10px;
}
.content-img:hover > button {
    transition: opacity 0.5s;
    opacity: 1;
}

.carousel_not_active{
    min-width: 6px;
    min-height: 6px;
    border-radius: 50%;
    /* clip-path: circle(); */
}
.carousel_active{
    background-color: #5ddf11;
    /* background-color: #ffffff; */
    border-radius: 50% ;
    /* clip-path: circle(); */
    width: 6.5px;
    height: 6.5px;
    /* transform: scale(1.5); */
}

.content-bio {
    margin-top: 12px;
    display: flex;
}
.content-bio > p {
    width: 92%;
    /* color: #000000; */
    margin-top:0;
    margin-bottom:10px;
    font-size: 16px;
}
.content-bio > button{
    min-width: 20px;
    background-color: transparent;
    border: none;
    margin-left: auto;
    /* margin-right: 30px; */
    cursor: pointer;
}
.content-bio > button > img {
    height: 25px;
}

.content-footer {
    display: flex;
    align-items: center;
    gap: 10px;
}
.content-footer p{
    /* color: #000000; */
    font-size: 20px;
    margin: 0;
    margin-right: 10px;
}
.content-footer > a {
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-footer button, .content-footer > a > button  {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
}
.content-footer button > img {
    height: 25px;
    width: 28px;
}

.settings-three-dot {
    position: absolute;
    right: 20px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    /* width: 240px; */
    padding: 20px;
    border: 2px solid black;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    background-color: #d1d1d1a7;
    backdrop-filter: blur(3px);
}
.settings-three-dot > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* width: 100%; */
    justify-content: space-between;
}
.settings-three-dot > div > section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    cursor: pointer;
}
.settings-three-dot > div > section > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    background-color: white;
    border: 1px solid black;
    border-radius: 50%;
}
.settings-three-dot > div > section > div > img {
    height: 30px;
}
.settings-three-dot > div > section > p{
    margin: 0;
    margin-top: 10px;
    /* margin-bottom: 20px; */
    text-wrap: nowrap;
}

/* .settings-three-dot > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.settings-three-dot > div:nth-child(2) > section {
    display: flex;
    gap: 20px;
    align-items: center;
}
.settings-three-dot > div:nth-child(2) > section > img {
    height: 25px;
    width: 25px;
}
.settings-three-dot > div:nth-child(2) > section > p {
    margin: 0;
} */



.home-hr-2{
    height: 98vh;
    flex:0;
    /* margin-bottom: 30px; */
    /* margin-top: 10px; */
    margin: auto 0;
    border: 1px solid rgb(158, 158, 158);
    border-radius: 1px;
}



/* BULLETIN side css */
.bulletin-sec > p {
    font-size: 25px;
    letter-spacing: 5px;
    font-weight: 700;
    width: fit-content;
    /* margin-left: 30px; */
    margin: 0px auto;
    margin-top: 25px;
}
.news-box-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}
.news-box {
    /* box-shadow: 3px 3px 10px  rgb(111, 111, 111); */
    /* background-color: #000000; */
    border-radius: 20px;
    padding: 20px;
}
.news-head {
    display: flex;
    gap: 10px;
}
.news-head p{
    display: flex;
    align-items: center;
    margin: 0;
    /* margin-right: 15px; */
    font-size: 18px;
    text-decoration: none;
}
.news-head img {
    height: 25px;
    display: flex;
    /* justify-content: center;
    align-items: center; */
}

.news-head button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.news-post-time {
    margin:0;
    margin-left: auto;
    font-weight: 500;

}

.news-caption {
    margin:0;
    margin-bottom: 10px;
    font-size: 20px;
    color: rgb(255, 80, 80);
}

.news-img {
    display: flex;
    justify-content: center;
}
.news-img img {
    width: 100%;
    height: 300px;
}
.bulletin-notifi {
    width: 25%;
    position: fixed;
    right: 20px;
    bottom: 10px;
}
.mobile-notifi {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 15;
}
.notifi-cross-icon {
    height: 20px;
    width: 20px;
}
.notification-sec {
    width: 100%;
    position: fixed;
    right: 0px;
    /* bottom: 10px; */
    height: 100%;
    border: 3px solid #BDFA3C ;
    border-radius: 10px;
    padding: 10px;
}

.notification-sec > div {
    display: flex;
    align-items: center;
}
.notification-sec > div > p {
    font-size: 20px;
    margin: 0px;
    margin-left: 10px;
    margin-right: auto;
}
.notification-sec > div > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.notification-sec > section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    /* overflow: scroll;
    scrollbar-width: none; */
}
.notification-sec > section > section > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98%;
    margin-bottom: 10px;
    padding: 10px 5px;
    border-radius: 10px;
    transition: 0.2s background-color;
    cursor: pointer;
}
.notification-sec > section > section > div:hover {
    background-color: rgba(128, 128, 128, 0.737);
}

.notification-sec > section > section {
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    padding: 5px;
}
.notification-sec > section > p {
    margin: 0;
    font-size: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.notification-sec > section > section > div > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
}
.notification-sec > section > section > div > p:nth-child(2) {
    margin: 0;
    width: 65%;
    text-wrap: wrap;
}
.notification-sec > section > section > div > p > h2 {
    display: inline;
    font-size: 15px;
    margin: 0;
    margin-right: 10px;
}
.notification-sec > section > section > div > p:nth-child(3) {
    margin: 0;
    margin-left: auto;
    text-wrap: nowrap;
}

/* Side Notifi */

.side-notification-sec {
    /* width: 25%;
    position: fixed;
    right: 20px;
    bottom: 10px; */
    height: 100%;
}

.side-notification-sec > section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    /* overflow: scroll;
    scrollbar-width: none; */
}
.side-notification-sec > section:nth-child(1) {
    height: fit-content;
}
.side-notification-sec > section > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}
.side-notification-sec > section > section > div {
    /* display: flex;
    align-items: center; */
    /* justify-content: center; */
    width: 98%;
    margin-bottom: 10px;
    padding: 13px 5px;
    border-radius: 10px;
    transition: 0.2s background-color;
    /* cursor: pointer; */
}
.side-notification-sec > section > section > div:hover {
    background-color: rgba(128, 128, 128, 0.737);
}

.side-notification-sec > section > section > div > div {
    display: flex;
    align-items: center;
}

.side-notification-sec > section > section {
    /* overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: none; */
    /* padding: 5px; */
}
.side-notification-sec > section > p, .side-notification-sec > section > div > p{
    margin: 0;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.side-notification-sec > section > section > div > div > img {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
}
.side-notification-sec > section > section > div > div > p:nth-child(2) {
    margin: 0;
    font-size: 15px;
    /* width: 65%; */
    text-wrap: wrap;
    color: white;
}
.side-notification-sec > section > section > div > div > p > h2 {
    display: inline;
    font-size: 15px;
    margin: 0;
    margin-right: 10px;
}
.side-notification-sec > section > section > div > div > p:nth-child(3) {
    margin: 0;
    font-size: 12px;
    margin-left: auto;
    text-wrap: nowrap;
}


.request_res {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}
.request_res > button {
    width: 40%;
    color: white;
    border-radius: 6px;
    padding: 4px;
}
.request_res > button:nth-child(1) {
    background-color: rgb(0, 145, 255);
    transition: background-color 0.4s;
}
.request_res > button:nth-child(1):hover {
    background-color: rgba(0, 145, 255, 0.683);
    transition: background-color 0.4s;
}
.request_res > button:nth-child(1):active {
    background-color: rgba(0, 145, 255, 0.5);
}

.request_res > button:nth-child(2) {
    background-color: rgb(48, 48, 48);
    transition: background-color 0.4s;
}
.request_res > button:nth-child(2):hover {
    background-color: red;
    transition: background-color 0.4s;
}
.request_res > button:nth-child(2):active {
    background-color: rgba(255, 0, 0, 0.781);
}



.share-box {
    position: absolute;
    bottom: 0;
    /* height: 75%; */
    /* width: 50%; */
    width: 40%;
    /* background-color: #38424B; */
    border-radius: 40px 40px 0px 0px;
    box-shadow: 1px 4px 15px 7px rgba(0, 0, 0, 0.44);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.share-box > section:nth-child(1) {
    display: flex;
    flex-direction: column;
    width: 92%;
}
.share-box > section:nth-child(1) > hr {
    width: 8%;
    border: 3px solid white;
    border-radius: 5px;
    align-self: center;
    margin-top: 5px;
}
.share-box > section:nth-child(1) > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.share-box > section:nth-child(1) > div > p {
    font-size: 30px;
    color: white;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}
.share-box > section:nth-child(1) > div > hr {
    width: 100%;
    border: 1px solid white;
    border-radius: 2px;
    margin-bottom: 20px;
}
.share-box > section:nth-child(1) > div > input {
    width: 95%;
    font-size: 18px;
    background-color: #DDD7D7;
    border: none;
    outline: none;
    letter-spacing: 2px;
    border-radius: 20px;
    padding: 2px 20px;
}

.share-box > section:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}
.share-box > section:nth-child(2) > div:nth-child(1) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    height: 220px;
    overflow: scroll;
    scrollbar-width: none;
    margin-top: 20px;
    /* background-color: red; */
}
.share-box > section:nth-child(2) > div:nth-child(1) > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100px;
    /* height: 100px;
    overflow: hidden; */
    margin: auto;
}
.share-box > section:nth-child(2) > div:nth-child(1) > div > img {
    height: 55px;
    width: 55px;
    border-radius: 50%;
}
.share-box > section:nth-child(2) > div:nth-child(1) > div > p {
    margin: 0;
    color: white;
    font-size: 15px;
    text-wrap: wrap;
    text-align: center;
    height: 18px;
    overflow: hidden;
}
.share-box > section:nth-child(2) > button {
    width: 98%;
    margin-top: 20px;
    background-color: #BDFA3C;
    border: 1px solid black;
    padding: 2px 0px;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
.share-box > section:nth-child(2) > div:nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 10px;
} 
.share-box > section:nth-child(2) > div:nth-child(3) > p {
    width: 81%;
    padding: 8.5px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #000000;
    color: #FFFFFF;
    margin: 0;
}
.share-box > section:nth-child(2) > div:nth-child(3) > button {
    display: flex;
    width: 100px;
    height: 41px;
    align-items: center;
    /* gap: 15px; */
    justify-content: space-between;
    border: 1px solid black;
    background-color: #0284FD;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 0px 5px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 2px;
    cursor: pointer;
}
.share-box > section:nth-child(2) > hr {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.share-box > section:nth-child(3) {
    display: flex;
    justify-content: space-around;
    /* gap: 10px; */
    width: 95%;
    margin-top: 15px;
    margin-bottom: 15px;
    overflow: scroll;
    scrollbar-width: none;
}
.share-box > section:nth-child(3) > button {
    background-color: transparent;
    border: none;
    cursor: pointer;

}
.share-box > section:nth-child(3) > button > img {
    height: 40px;
    min-width: 40px;
}
.action-cross-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    transition: 0.5s background-color;
    cursor: pointer;
}
.action-cross-btn > img {
    height: 20px;
}
.action-cross-btn > img:hover {
    animation: rotate 0.2s linear;
}
@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(90deg);
    }
}

.home-top-logo {
    display: none;
}
.home-top-logo > div {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 10px;
}
.home-top-logo > div > img {
    height: 40px;
}
.home-top-logo > div > button {
    margin-left: auto;
}
.home-top-logo > div > button > img {
    height: 25px;
    width: 25px;
}

.story_view_main {
    height: 100vh;
    display: flex;
    align-items: center;
}
.story_view_main > a {
    position: absolute;
    top: 20px;
    right: 20px;
}
.story_view_main > a > img {
    height: 20px;
}
.story_view_main > section {
    height: 80%;
    width: 100%;
    /* background-color: red; */
    /* justify-content: left; */
    
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 0px 20px 0px 20px;
    overflow: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
}

/* .story_view_main > section > div:nth-child(1) > div:nth-child(2) > div:nth-child(1){
    display: flex;
    width: 100%;
    justify-content: center;
} */

.story_view_main > section > div:nth-child(1) {
    margin-left: 40%;
}
.story_view_main > section > div:last-child {
    margin-right: 40%;
}

.story_view_main > section > div {
    border-radius: 10px;
    scroll-snap-align: center;
}

.story_view_main > section > .story_blur {
    transition: scale 0.4s, filter 0.4s;
    scale: 0.5;
    filter: blur(10px);
}
.story_view_main > section > .story_focus {
    transition: scale 0.4s, filter 0.4s;
    scale: 1;
    filter: blur(0px);
}


.story_content_view {
    width: 380px;
    position: relative;
}
.story_content_view > img {
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.story_content_view > div {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 20px;
}
.story_content_view > div > img {
    height: 30px;
    width: 30px;
    clip-path: circle();
}

.scroll_to_top {
    /* absolute bottom-3 right-[340px] w-10 h-10 rounded-full flex justify-center items-center bg-white */
    position: fixed;
    bottom: 20px;
    right: calc(100% - 77%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(182, 182, 182, 0.674);
    cursor: pointer;
    transition: background 0.2s;
}
.scroll_to_top:hover {
    transition: background 0.2s;
    background: white;
}

/* MEDIA QUERY */
@media screen and (min-width: 320px) and (max-width: 570px) {

    .home_main {
        width: 100%;
        height: 100%;
    }
    .box {
        width: 95%;
        padding: 10px;
        border-top-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    .grey-side {
        display: none;
    }
    .bulletin-outer {
        display: none;
    }
    .home-hr{
        width: 98%;
        margin:auto
    }
    .home-hr-2 {
        display: none;
    }
    .white-side {
        width: 100%;
    }
    .home-nav-icon {
        position: fixed;
        display: flex;
        bottom: 10px;
        justify-content: space-between;
        background-color: #38424B;
        width: 90%;
        padding: 10px 10px;
        /* border-radius: 0px; */
        z-index: 8;  
    }
    .home-story-sec {
        margin-top: 20px;
    }
    .home-top-logo {
        display: contents;
    }

    .content-head > div > a > p {
        font-size: 15px;
    }
    .content-head > div > p {
        font-size: 13px;
        margin-top: 5px;
    }
    .settings-three-dot {
        gap: 20px;
    }
    .settings-three-dot > div > section > p {
        font-size: 15px;
    }
    .settings-three-dot > div > section > div {
        height: 45px;
        width: 45px;
    }
    .settings-three-dot > div > section > div > img {
        height: 25px;
    }
    .content-bio > button {
        margin-right: 0px;
    }

    /* Comment */
    .comment_box {
        width: 100%;
    }
    .comment_top > p {
        font-size: 25px;
    }

    .comment_text { 
        height: 100%;
    }
    .comment_text > div >div  {
        display: flex;
        flex-direction: column;
    }

    .comment_text > div:nth-child(1) > div > div:nth-child(1) > img {
        height: 30px;
        width: 30px;
    }
    .comment_text > div:nth-child(1) > div > div:nth-child(1) > p {
        font-size: 15px;
    }
    .comment_text > div:nth-child(1) > div > p {
        font-size: 15px;
        margin-left: 40px;
    }
    .comment_text > div:nth-child(1) > div > div:nth-child(3) {
        margin-left: 40px;
    }
    .reply_comment {
        width: 100%;
    }
    .reply_comment {
        margin-left: 0px;
    }
    .reply-comment-text > div > div > div:nth-child(1) > img {
        height: 30px;
        width: 30px;
    }
    .reply-comment-text > div > div > div:nth-child(1) > p:nth-child(2) {
        font-size: 15px;
    }
    .reply-comment-text > div > div > p {
        font-size: 15px;
    }

    /* Share */
    .share-box {
        width: 100%;
    }
    .share-box > section:nth-child(1) > div > p {
        font-size: 25px;
    }
    .share-box > section:nth-child(2) > div:nth-child(1) {
        grid-template-columns: repeat(3, 1fr);
        gap: 14px;
    }
    .share-box > section:nth-child(2) > div:nth-child(1) > div > img {
        height: 50px;
        width: 50px;
    }
    .share-box > section:nth-child(2) > button {
        font-size: 18px;
        padding: 0px 0px;
    }
    .share-box > section:nth-child(2) > div:nth-child(3) > button {
        font-size: 15px;
        gap: 10px;
    }
    .share-box > section:nth-child(2) > div:nth-child(3) > button > img {
        height: 15px;
    }
    .share-box > section:nth-child(3) {
        width: 350px;
        justify-content: flex-start;
        gap: 25px;
        overflow: scroll;
    }

    .notification-sec > section > p {
        font-size: 20px;
    }
    .notification-sec > section > section > div {
        margin-bottom: 5px;
    }
    .notification-sec  {
        border: none;
    }

    .notification-sec > div:nth-child(2) {
        flex-direction: column;
        margin-top: 10px;
    }
}