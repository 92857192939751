.right-sec {
    height: 110vh;
    width: 100%;
    display: flex;
}

/* right sec */
.settings-edit-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 60%; */
    width: 100%;
    height: 100vh;
    position: relative;
    /* margin-left: 50px; */
    overflow: scroll;
    scrollbar-width: none;
    
}
.title {
    position: absolute;
    top: 35px;
    margin: 0;
    font-size: 25px;
    font-weight: 600;
    /* color: white; */
}
.settings-edit-profile > img {
    height: 40%;
    max-height: 290px;
    width: 80%;
    border-bottom-left-radius: 180px;
    border-bottom-right-radius: 180px;
}
.profile-img {
    height: 150px;
    width: 150px;
    /* height: 20%;
    width: 20%; */
    position: absolute;
    top: 200px;
}
.profile-img > img:nth-child(1) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 7px solid white;
    border-radius: 20px;
    z-index: 1;
}
.profile-img > button > img {
    height: 100%;
    width: 100%;
    cursor: pointer;
    /* position: absolute;
    bottom: -20px;
    right: -40px;
    z-index: 2; */
}
.profile-img > button {
    height: 50px;
    width: 70px;
    position: absolute;
    bottom: -10px;
    right: -30px;
    z-index: 2;
    background-color: transparent;
    border: none;
}

.edit-input {
    width: 70%;
    height: 80%;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.edit-input > p {
    font-size: 18px;
    margin: 0;
    /* margin-bottom: 10px; */
}

.edit-input > input {
    width: 100%;
    height: 20px;
    outline: none;
    /* border: none; */
    background-color: transparent;
    font-size: 20px;
    font-weight: 500;
    /* border-bottom: 2px solid; */
    /* margin-bottom: 30px;
    padding-bottom: 10px; */
}
.edit-input > input[type="text"] {
  --tw-ring-color: #ffffff00;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.edit-input > button {
    width: fit-content;
    background-color: transparent;
    border: none;
    font-size: 15px;
    cursor: pointer;
}
.edit-input > div {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 30px; */
}
.edit-input > div > button {
    background-color: #B9F53B;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 60px;
    cursor: pointer;
}
.edit-input > div > button:hover {
  background-color: #befa3cd0;
}
.edit-input > div > button:active {
  background-color: #befa3cac;
}

.right-sec > hr {
    margin: 0;
    border: 1px solid rgb(122, 122, 122);
    border-radius: 1px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
}


.edit-profile-cam {
    color: transparent;
  }
  .edit-profile-cam::-webkit-file-upload-button {
    visibility: hidden;
    font-family: 'Ubuntu', sans-serif;
  }
  .edit-profile-cam::before {
    z-index: 3;
    height: 60px;
    width: 70px;
    content: '';
    display: inline-block;
    background: none;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    position: absolute;
    bottom: -25px;
    left: 120px;
  }
  /* .edit-profile-cam:hover::before {
    border-color: black;
  } */
  .edit-profile-cam:active {
    outline: 0;
  }
  .edit-profile-cam:active::before {
    background: none; 
  }


/* Settings */

.setting-sec {
    width: 45%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* overflow: scroll;
    scrollbar-width: none; */
}
.setting-sec > p {
    height: fit-content;
    font-size: 25px;
    font-weight: 700;
    margin-top: 35px;
    margin-bottom: 20px;
}

.setting-options {
    width: 85%;
    /* height: 88%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    padding-top: 10px;
    padding-bottom: 50px;
    overflow: scroll;
    scrollbar-width: none;
}

.setting-heading {
  /* margin-bottom: 10px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.setting-heading > div {
    display: flex;
    align-items: center;
    /* margin-top: 15px;*/
}

.setting-options > hr {
    width: 95%;
    /* border: 1px solid black; */
    border-radius: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.setting-heading > div > img {
    height: 25px;
    width: 25px;
}
.setting-heading > div > button {
    border: none;
    background-color: transparent;
    font-size: 20px;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;
}
.setting-heading > div > button:nth-child(2) {
  margin-right: auto;
}
/* .setting-heading > div > button:nth-child(3) {
  margin-left: auto;
} */
.setting-heading > div > button:nth-child(3) > img {
  height: 10px;
}

.setting-sub-heading {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    /* margin-bottom: 10px; */
}
.setting-sub-heading > a > button {
    margin: 0;
    /* margin-top: 10px;
    margin-bottom: 10px; */
    display: flex;
    align-items: center;
    padding: 7px 10px;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 10px;
    width: 95%;
    text-align: start;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 5px;
    transition: 0.2s border;
}
.setting-sub-heading > a > button:hover {
  /* background-color: rgba(130, 130, 130, 0.724); */
  border: 2px solid #BDFA3C;
}
.setting-sub-heading > a > button:focus {
  /* background-color: rgba(130, 130, 130, 0.724); */
  border: 2px solid #BDFA3C;
}
.setting-sub-heading > a > button > img {
  height: 18px;
  margin-right: 10px;
}

.setting-option-with-btns {
  margin-left: 40px;
}
.setting-option-with-btns > div {
    display: flex;
    align-items: center;
}
.setting-option-with-btns > div > div {
  width: 100%;
  margin-bottom: 10px;
}
.setting-option-with-btns > div > div > p {
  font-size: 15px;
  font-weight: 500;
  /* margin: 0;
  margin-bottom: 10px; */
}
.setting-option-with-btns > div > img {
  height: 25px;
  width: 25px;
}
.setting-option-with-btns > div > button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.setting-option-with-btns > div > button:nth-child(2) {
  margin-left: auto;
}
.setting-option-with-btns > div > button:nth-child(2) > img {
  height: 10px;
}
.setting-option-with-btns > p {
  width: 97%;
  margin: 0;
  font-size: 12px;
  line-height: 18px;
}

.log-out-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    /* margin-bottom: 50px; */
}
.log-out-btn > button > img {
    height: 30px;
    width: 30px;
    margin-right: 5px;
}
.log-out-btn > button {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 5px;
    border: 2px solid transparent;
    font-size: 20px;
    font-weight: 600;
    transition: 0.5s background-color, 0.5s border-color;
    cursor: pointer;
}
.log-out-btn > button:hover {
  background-color: rgba(255, 0, 0, 0.721);
  border-color: rgba(66, 66, 66, 0.774);
}

.edit-select-image {
    height: 60%;
    width: 100%;
    display: flex;
    gap: 50px;
    margin-top: 70px;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
}
/* .edit-select-image button {
    background-color: #BDFA3C;
    border: none;
    font-size: 23px;
    font-weight: 600;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
} */
.edit-select-image > section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.edit-select-image > section > img {
    height: 200px;
    width: 200px;
    object-fit: cover;
}

.relative-edit-btn {
  background-color: #BDFA3C;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  width: 200px;
  height: fit-content;
  position: relative;
  
}
.relative-edit-btn:hover {
  background-color: #befa3cd0;
}
.relative-edit-btn:hover {
  background-color: #befa3cac;
}
.edit-profil-img-input {
  color: transparent;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.edit-profil-img-input::-webkit-file-upload-button {
  visibility: hidden;
  font-family: 'Ubuntu', sans-serif;
}
/* .edit-profil-img-input::before { */
  /* content: 'Add Profile Image'; */
  /* height: 200px; */
  /* width: 100%; */
  /* color: black;
  display: inline-block; */
  /* background: rgb(189, 250, 60); */
  /* background-color: red; */
  /* border: 1px solid  rgb(188,187,187);
  border-radius: 10px;
  padding: 10px 33px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  width: 70%; */
/* } */

.edit-profil-img-input:active {
  outline: 0;
}
.edit-profil-img-input:active::before {
  background: rgb(189, 250, 60); 
}


.edit-cover-img-input {
  color: transparent;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;}
.edit-cover-img-input::-webkit-file-upload-button {
  visibility: hidden;
  font-family: 'Ubuntu', sans-serif;
}
/* .edit-cover-img-input::before {
  content: 'Add Cover Image';
  color: black;
  display: inline-block;
  background: rgb(189, 250, 60);
  border: 1px solid  rgb(188,187,187);
  border-radius: 10px;
  padding: 10px 33px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
} */
.edit-cover-img-input:active {
  outline: 0;
}
.edit-cover-img-input:active::before {
  background: rgb(189, 250, 60); 
}

.select-img-done {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 0;
  right: 0;
}
.select-img-done > button{
  background-color: #BDFA3C;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 5px 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
.select-img-done > button:hover {
  background-color: #befa3cd0;
}
.select-img-done > button:hover {
  background-color: #befa3cac;
}


/* Change Password Page */

.setting-change-pass  > p {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.setting-change-pass  > div > a {
  /* text-decoration: none; */
  font-size: 16px;
  color: #0056FD;
}
.setting-change-pass  > div {
  width: 60%;
}
.setting-change-pass  > div > p {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 10px;
}
.setting-change-pass  > div > input {
  width: 100%;
  text-align: center;
}

/* Push Notification */

.settings-push-notif > section {
  width: 70%;
}
.settings-push-notif > p {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 30px;
  margin-bottom: 20px;
}
/* .settings-push-notif > section > p {
  font-weight: 500;
  font-size: 23px;
  margin: 15px 0;
} */
.settings-push-notif-scroll {
  width: 80%;
  height: 100vh;
  overflow: scroll;
  scrollbar-width: none;
  padding-left: 5px;
}
.settings-push-notif-scroll > section > p {
  font-weight: 500;
  font-size: 23px;
  margin: 15px 0;
}
.settings-push-notif-scroll > section > div > input {
  cursor: pointer;
}

/* Email Switch */
 .Email-switch {
  display: flex;
  align-items: center;
}
.Email-switch > p {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  margin-right: auto;
}
/*.Email-switch > input[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}

.Email-switch > label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 20px;
  background: grey;
  border: 2px solid #BDFA3C;
  display: block;
  border-radius: 100px;
  position: relative;
}

.Email-switch > label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: #BDFA3C;
  border-radius: 90px;
  transition: 0.2s;
}

.Email-switch > input:checked + label {
  background: black;
}

.Email-switch > input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.Email-switch > label:active:after {
  width: 20px;
} */

/* Email notification */
.settings-email-notifi > p {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.settings-email-notifi > section {
  width: 80%;
  margin: 10px 0;
}

/* Language */
.lang-search-bar{
  width: 90%;
  letter-spacing: 2px;
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  /* justify-content: center; */
}
.lang-search-bar > input{
  letter-spacing: 2px;

  width: 100%;
  background-color: #DDD7D7;
  border: none;
  border-radius: 30px;
  font-size: 20px;
  letter-spacing: 5px;
  padding: 10px 30px;
  outline: none;
}

.settings-language > p {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.settings-language > section {
  display: flex;
  flex-direction: column;
  width: 85%;
  overflow: scroll;
  scrollbar-width: none;
}
.settings-language > section > button {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  padding: 10px;
  margin: 5px 0px;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s background-color ease-in;
}
.settings-language > section > button:hover {
  background-color: rgba(161, 161, 161, 0.801);
}
.settings-language > section > button:focus {
  background-color: rgba(161, 161, 161, 0.801);
}

/* Blocked Account */
.settings-blocked {
  display: flex;
}
.settings-blocked > p:nth-child(1) {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.settings-blocked > p:nth-child(2) {
  font-size: 17px;
  color: #626262;
  margin-bottom: 20px;
}
.settings-blocked > p:nth-child(4) {
  font-size: 17px;
  color: #626262;
  text-align: center;
  position: static;
  bottom: 10px;
}
.settings-blocked > section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 70%;
  overflow: scroll;
  scrollbar-width: none;
  cursor: pointer;
}
.blocked-accs {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 20px;
}
.blocked-accs > div:nth-child(1) {
  display: flex;
  align-items: center;
}
.blocked-accs > div:nth-child(1) > img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.blocked-accs > div:nth-child(1) > p {
  margin: 0;
}
.blocked-accs > div:nth-child(2) {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.blocked-accs > div:nth-child(2) > p {
  margin: 0;
  margin-right: auto;
  margin-left: 40px;
  font-weight: 600;
}

/* Sensitivity Content */
.settings-sensi-content > p:nth-child(1) {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.settings-sensi-content > p:nth-child(2) {
  font-size: 17px;
  color: #626262;
  margin-bottom: 20px;
}
.settings-sensi-content > p:nth-child(4) {
  width: 90%;
  font-size: 17px;
  color: #626262;
  text-align: center;
  position: static;
  bottom: 10px;
}
.settings-sensi-content > section {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 80%;
  height: 70%;
  overflow: scroll;
  scrollbar-width: none;
  cursor: pointer;
  padding-left: 5px;
}
.settings-sensi-content > section > div > input {
  cursor: pointer;
}
.settings-sensi-content > section > div > label > p {
  font-size: 17px;
  margin: 0;
}
.settings-sensi-content > section > div > label > p:nth-child(2) {
  color: #626262;
  margin-top: 8px;
}
.sensi-option {
  display: flex;
  justify-content: space-between;
}

/* Deactivate Acc */
.settings-deactivate-acc > p:nth-child(1) {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.settings-deactivate-acc > p:nth-child(2) {
  font-size: 17px;
  color: #626262;
  margin-bottom: 20px;
}
.settings-deactivate-acc > p:nth-child(4) {
  width: 90%;
  font-size: 17px;
  color: #626262;
  text-align: center;
  position: static;
  bottom: 10px;
}
.settings-deactivate-acc > section {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 50px;
  /* width: 75%; */
  height: 70%;
  overflow: scroll;
  scrollbar-width: none;
}
.settings-deactivate-acc > section > p {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
}

/* Delete start */
.settings-delete-start > p:nth-child(1) {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.settings-delete-start > p:nth-child(2) {
  font-size: 17px;
  color: #626262;
  margin-bottom: 20px;
}
.settings-delete-start > p:nth-child(4) {
  width: 90%;
  font-size: 17px;
  color: #626262;
  text-align: center;
  position: static;
  bottom: 10px;
}
.settings-delete-start > section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80%;
  overflow: scroll;
  scrollbar-width: none;
}
.settings-delete-start > section > p {
  font-size: 16px;
}
.settings-delete-start > section > div {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 75%;
}
.settings-delete-start > section > div > section {
  display: flex;
  width: 100%;
}
.settings-delete-start > section > div > section:nth-child(1) > p {
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}
.settings-delete-start > section > div > section:nth-child(1) > button > img {
  height: 10px;
}
.settings-delete-start > section > div:nth-child(2) > section:nth-child(2) {
  display: flex;
  flex-direction: column;
  width: 98%;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.settings-delete-start > section > div:nth-child(3) > section:nth-child(2){
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* Why Delete */
.settings-why-delete > p:nth-child(1) {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.settings-why-delete > p:nth-child(2) {
  font-size: 17px;
  text-align: center;
  color: #626262;
  margin-bottom: 20px;
  width: 90%;
}
.settings-why-delete > section {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.settings-why-delete > section > p {
  font-weight: 500;
}
.settings-why-delete > section > section {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.settings-why-delete > section > section > textarea {
  background-color: #DDD7D7;
  padding: 5px 15px;
  font-size: 15px;
  align-self: center;
  width: 80%;
  outline: none;
  border-radius: 20px;
  border: none;
  resize: none;
  --tw-ring-color: #ffffff00;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.general_theme_btn {
  border: none;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 60px;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 570px) {
  .settings-mobile-div {
    display: none;
  }
  .settings-hr {
    display: none;
  }
  .setting-sec {
    width: 100%;
    padding-bottom: 30px;
  }
  .settings-edit-profile {
    padding-bottom: 100px;
  }
  .settings-edit-profile > img {
    /* height: 800px; */
    min-height: 350px;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .profile-img {
    top: 120px;
    height: 130px;
    width: 130px;
  }
  .profile-img > button {
    width: 50px;
    height: 40px;
    right: -20px;
  }
  .edit-input {
    margin-top: 30px;
  }
  .edit-input > p {
    font-size: 16px;
  }
  .edit-input > input {
    font-size: 18px;
  }
  .edit-input > div > button {
     padding: 5px 25px;
  }
  .lang-search-bar > input {
    letter-spacing: 0px;
    font-size: 18px;
    padding: 5px 10px;
  }
  .settings-language > section > button{
    font-size: 15px;
  }
  .settings-push-notif-scroll > section > p {
    font-size: 18px;
  }
  .settings-blocked > p:nth-child(2) {
    font-size: 15px;
  }
  .settings-blocked > p:nth-child(4) {
    font-size: 15px;
  }
  .settings-sensi-content > p:nth-child(2) {
    text-align: center;
    font-size: 15px;
  }
  .settings-sensi-content > p:nth-child(4) {
    font-size: 15px;
  }
  .settings-delete-start > p:nth-child(2) {
    font-size: 15px;
  }
  .settings-delete-start > section > p {
    font-size: 14px;
    text-align: center;
  }
  .settings-delete-start > section > div:nth-child(2) > section:nth-child(2) > p {
    font-size: 15px;
  }
  .settings-delete-start > section > div:nth-child(3) > section:nth-child(2) > p {
    font-size: 15px;
  }
  .settings-deactivate-acc > p:nth-child(2) {
    text-align: center;
    font-size: 15px;
  }
  .settings-deactivate-acc > p:nth-child(4) {
    font-size: 15px;
  }
  .acc-edit-post-box {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  .edit-post-button {
    margin-left: 20px;
  }
  .edit-select-image {
    height: 70%;
    justify-content: flex-start;
    flex-direction: column;
    overflow: scroll;
    scrollbar-width: none;
  }
  .edit-select-image > section > img {
    width: 100%;
    height: max-content;
  }
  .select-img-done {
    bottom: -20px;
  }
}
