/* App.css */
.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-content {
    display: flex;
    overflow: hidden;
    width: 300px; /* Adjust as needed */
    height: 200px; /* Adjust as needed */
    position: relative;
  }
  
  .carousel-item {
    min-width: 100%;
    transition: transform 0.5s ease-in-out;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .carousel-item.active {
    opacity: 1;
    position: relative;
    transform: translateX(0);
  }
  