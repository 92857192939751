.main-div {  
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 10px;
    font-family: 'ubuntu', sans-serif;
    height: 100vh;
}
/* .main-div > :nth-child(2) {
  position: relative;
  height: 100%;
} */

.three-dots {
    margin-top: 30px;
    /* font-size: 1rem; */
}
.circle{
    display: inline-block;
    margin: 0px;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(217,217,217);
}
.circle-color {
    background-color: rgb(189, 250, 60);
}

.profile-setup {
    /* height:300px; */
    height: 100%;
    margin-left: 20px;
    margin-top: 50px;
}

.main-div > h1{
    font-size: 1.8rem;
    margin:0;
    margin-top: 50px;
    margin-bottom:110px ;
}

/* INPUT */
.input-dp {
    display: flex;
    flex-direction: column;
}
.photo-input{

}

.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  font-family: 'Ubuntu', sans-serif;
}
.custom-file-input::before {
  content: 'Add Your Image';
  color: black;
  display: inline-block;
  background: rgb(189, 250, 60);
  border: 1px solid  rgb(188,187,187);
  border-radius: 10px;
  padding: 10px 40px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 50px;
  margin-left: 15px;
}
/* .custom-file-input:hover::before {
  border-color: black;
} */
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: rgb(189, 250, 60); 
}
/* INPUT */

.photo-btn{
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.2rem;
    padding: 10px 40px;
    margin-bottom: 50px;

    border: 1px solid rgb(188,187,187);
    border-radius: 10px;
    background-color: rgb(189, 250, 60);
    cursor: pointer;
    transition: opacity 0.15s;
}
.photo-btn:hover{
    opacity: 0.8;
}
.photo-btn:active {
    opacity: 1;
}

.skip-btn {
    border:none;
    font-family: 'Ubuntu', sans-serif;
    background-color: white;
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 900;
    cursor: pointer;
}
@media screen and (min-width: 320px) and (max-width: 570px) {
  .three-dots {
    margin-top: 15px;
  }
  .profile-setup {
    height: 100px;
  }
  .main-div > h1 {
    font-size: 25px;
  }
}
