/* body{
    padding: 0;
    margin: 0;
    font-family: 'Ubuntu',sans-serif;
} */
/* .search-main {
    display: flex;
    font-family: 'Ubuntu',sans-serif;
    overflow-x: hidden;
    height: 100vh;
} */
/* .left-sec {
    display: flex;
    justify-content: start;
    align-items: center;
    position: sticky;
    top:0;
    flex-direction: column;
    padding: 20px 30px 0px 30px;
} */
.search-right-sec {
    width: 100%;
    /* min-width: 500px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    height: 100vh;
    overflow-y: scroll;
}

/* left sec css */
/* .twigyy-logo {
    height: 50px;
    filter: drop-shadow(4px 4px 0px #adadad);
}
.left-sec > div {
    margin-bottom: auto;
}

.nav-icon {
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: space-between;
    align-items: center;
    background-color: #38424B;
    padding: 18px 15px;
    border-radius: 10px;       
}

.nav-icon > button{
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}
.normal-icon {
    background-color: transparent;
    border: none;
    border-radius: 50%;
}
.focus-icon {
    background-color: #B9F53B;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: none;
}
.focus-icon > img {
    height: 100%;
    width: 100%;
    filter: invert();
}
.nav-icon img {
    height: 35px;
} */


/* Right sec css */

.search-bar {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    position: relative;
}
.search-bar > img {
    height: 40px;
    background-color: #DDD7D7;
    padding: 5px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
.search-bar > input{
    width: 900px;
    background-color: #DDD7D7;
    border: none;
    /* border-radius: 30px; */
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    font-size: 20px;
    letter-spacing: 2px;
    padding: 5px;
    outline: none;
}
.friend_list_sp {
    background-color: #14283a;
    width: 850px;
    height: fit-content;
    max-height: 500px;
    border-radius: 10px;
    position: absolute;
    top: 50px;
    z-index: 50;
    overflow-y: scroll;
    scrollbar-width: none;
}
.friend_list_sp > a {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    color: white;
}
.friend_list_sp > a > img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    clip-path: circle()
}

/* Twigs sec */

.twigs-type {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    /* width: 85%; */
}
/* .twigs-type > div {
    height: 30%;
    width: 15%;
} */
.twigs-btn {
    height: 100%;
    width: 100%;
    background-color: transparent;
    padding: 0;
    border: none; 
    /*padding: 20px 0px;
    padding-left: 8%;
    padding-right: 8%; */
    border-radius: 50px;
    font-size: 18px;
    color: white;
    font-weight: 700;
    letter-spacing: 2px;
    cursor: pointer;
}
/* .twigs-type > div > img {
    position: relative;
    filter: brightness(70%);
    top: -100%;
    margin: 0;
    height: 100%;
    width: 100%;
    border-radius: 50px;
    z-index: -1;
} */
.more-btn{
    height: 50px;
    width: 100px;
    padding: 20px 10px;
    /* color: black; */
    border-radius: 50px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 2px;
    cursor: pointer;
}


.twigs-btn-test {
    height: 50px;
    width: 200px;
    border-radius: 50px;
    font-size: 18px;
    color: white;
    font-weight: 700;
    letter-spacing: 2px;
    cursor: pointer;
    position: relative;
    transition: transform 0.4s;
    z-index: none;
}
.twigs-btn-test > img {
    height: 100%;
    width: 100%;
    filter: brightness(70%);
    position: absolute;
    top: 0px;
    border-radius: 50px;
    z-index: -1;
}
.twigs-btn-test:hover {
    transition: transform 0.4s;
    transform: scale(1.1);
}



/* Feeds sec css */
/* .feeds-box {
    overflow: hidden;
} */
.feeds-header{
    display: flex;
    margin-bottom: 20px;
}
.feeds-header > p{
    margin: 0;
    font-size: 28px;
    font-weight: 500;
}
.feeds-header > button {
    margin-left: auto;
    margin-right: 100px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

/* feeds img sec */
.feeds-sec {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    gap: 25px;
    /* width: 95%; */
    width: 100%;
}
.col-1 > div, .col-2 > div {
    height: max-content;
}
.col-1, .col-2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* width: 300px; */
}

.col-1 > div > img {
    /* height: 300px; */
    width: 300px;
    aspect-ratio: 3/3;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 20px;
    /* object-fit: cover; */
}

.col-2 > div > img {
    height: 300px;
    width: 300px;
    border-radius: 20px;
    margin-bottom: 20px;
    object-fit: cover;
}

.grid-box {
    width: 100%;
    display: flex;
    
}

.post-img-center {
    display: flex;
    justify-content: center;
}
.img-grid{
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    width: 86%;
    /* justify-content: center; */
    /* padding: 0px 10px; */
}
.img-grid > div > img, .img-grid > a > img, .img-grid > a > div > img {
    height: clamp(110px, 17vw, 400px);
    width: clamp(110px, 17vw, 400px);
    aspect-ratio: 3/3;
    object-fit: cover;
    border-radius: 20px;
    /* margin-bottom: 20px; */
    /* object-fit: cover; */
}

@media screen and (min-width: 320px) and (max-width: 570px) {

    .search-bar > input {
        padding: 3px 0px;
        letter-spacing: 3px;
        width: 85%;
    }
    .feeds-sec {
        width: 100%;
    }
    .feeds-header > p {
        margin-left: 10px;
    }
    .feeds-header > button {
        margin-right: 20px
    }
    .img-grid{
        gap: 0px;
        justify-content: space-between;
        /* padding: 0px 8px; */
    }
    .img-grid > div > img,  .img-grid > a > img, .img-grid > a > div > img {
        height: 110px;
        width: 110px;
        margin-bottom: 10px;
    }
    .twigs-type {
        padding: 0px 5px;
        /* justify-content: space-between; */
    }
    .twigs-btn-test {
        width: 150px;
        margin-bottom: 10px;
    }
    .more-btn {
        width: 400px;
    }

    .col-1 > div > img {
        width: 110px;
        margin-bottom: 10px;
    }
    .friend_list_sp{
        width: 98%;
    }
}