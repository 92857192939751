.someone-acc-about-sec {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 95%;
}


.someone-about-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.someone-about-description > div:nth-child(1) {
    width: 60%;
}
.someone-about-description > div > h2 {
    font-size: 25px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
}
.someone-about-description > div > p {
    margin: 0;
    font-size: 17px;
}
.someone-about-description > button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #BDFA3C;
    font-size: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.action-btn {
    display: flex;
    width: 20%;
    justify-content: space-between;
}
.action-btn > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #BDFA3C;
    border: none;
    border-radius: 20px;
    padding: 8px 20px;    
    cursor: pointer;
}
.action-btn > button > img {
    height: 40px;
    width: 30px;
}
.action-btn > button:hover {
    background-color: #befa3cd0;
}
.action-btn > button:active {
    background-color: #befa3cac;
}
.someone-middle-sec {
    display: flex;
    align-items: center;
    gap: 75px;
    margin-top: 20px;
}

.someone-acc-friends {
    height: fit-content;
    position: relative;
    width: 25%;
}
.someone-acc-friends > div:nth-child(1) {
    display: flex;
}
.someone-acc-friends > div > p {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}
.someone-acc-friends > div:nth-child(1) > button {
    background-color: transparent;
    border: none;
    margin-left: auto;
    font-size: 15px;
    cursor: pointer;
}

.someone-acc-friends > div:nth-child(2) {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.someone-acc-friends > div > img {
    height: 60px;
    min-width: 80px;
    border-radius: 40px;
    object-fit: cover;
    position: relative;
    cursor: pointer;
}
.mutual-img-0 {
    /* z-index: 5; */
    box-shadow: 1px 1px 10px 2px black;
}
.mutual-img-1 {
    right: 15%;
    /* z-index: 4; */
    box-shadow: 1px 1px 10px 2px black;
}
.mutual-img-2 {
    right: 30%;
    /* z-index: 3; */
    box-shadow: 1px 1px 10px 2px black;
}
.mutual-img-3 {
    right: 45%;
    /* z-index: 2; */
    box-shadow: 1px 1px 10px 2px black;
} 
.mutual-img-4 {
    right: 60%;
    box-shadow: 1px 1px 10px 2px black;
}
.someone-acc-friends > div:nth-child(2) > button {
    position: relative;
    right: 40%;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.someone-suggestion {
    display: flex;
    /* justify-content: space-between; */
    gap: 20px;
    /* width: 65%; */
    /* overflow: scroll;
    scrollbar-width: none; */
    position: relative;
}
.someone-suggestion > button {
    position: absolute;
    top: 35%;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.5s;
}
.someone-suggestion > button:nth-child(1) {
    left: 0px;    
}
.someone-suggestion > button:nth-last-child(1) {
    right: 0px;    
}
.someone-suggestion:hover > button {
    opacity: 1;
    transition: opacity 0.5s;
}

.someone-suggestion > section {
    width: 100%;
    display: flex;
    gap: 20px;
    overflow: scroll;
    scrollbar-width: none;
}
.someone-suggestion > section > div, .someone-suggestion > section > a > div {
    position: relative;
    width: fit-content;
}
.someone-suggestion > section > div > img, .someone-suggestion > section > a > div > img {
    height: 170px;
    min-width: 150px;
    border-radius: 25px;
    object-fit: cover;
    cursor: pointer;
}
.someone-suggestion > section > div > div, .someone-suggestion > section > a > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 20px; */
    position: absolute;
    bottom: 15px;
    left: 50%;
    right: 50%;
}
.someone-suggestion > section > div > div > p, .someone-suggestion > section > a > div > div > p {
    margin: 0;
    color: white;
    font-size: 15px;
    font-weight: 500;
    text-wrap: nowrap;
}
.someone-suggestion > section > div > button > img, .someone-suggestion > section > a > div > button > img {
    height: 10px;
    min-width: 10px;
}
.someone-suggestion > section > div > button, .someone-suggestion > section > a > div > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #BDFA3C;
    border: none;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;

    box-shadow: 1px 1px 3px rgb(67, 67, 67);
    position: absolute;
    top: 10px;
    right: 10px;
}
.acc-size {
    margin-top: 30px;
    width: 95%;
}

.lock-someone-profile > section:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.lock-someone-profile > section:nth-child(1) > h4 > img {
    height: 16px;
}
.lock-someone-profile > section:nth-child(1) > h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.lock-someone-profile > section:nth-child(2) {
    margin-top: 20px;
    height: 200px;
    position: relative;
    overflow: hidden;
}
.lock-someone-profile > section:nth-child(2) > h4 > img {
    height: 16px;
}
.lock-someone-profile > section:nth-child(2) > h4 {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 0;
}
.lock-someone-profile > section:nth-child(2) > img {
    position: absolute;
    bottom: -110px;
    right: -90px;
}


.combine-friends-sec {
    position: absolute;
    bottom: 0px;
    /* background-color: #38424B; */
    /* background-color: #141414cc; */
    width: 80%;
    height: 90%;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    padding: 30px 30px;
}
.my_friends {
    display: flex;
    gap: 15px;
    flex-direction: column;
    width: 100%;
    height: 100% !important;
    margin-top: 30px;
    padding-bottom: 100px;
}
.my_friends > a {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.my_friends > a > img {
    height: 28px;
    width: 28px;
    border-radius:50%;
    object-fit: cover;
}

@media screen and (min-width: 320px) and (max-width: 570px) {
    .accounts-right-sec {
        /* overflow: hidden; */
        padding-bottom: 80px;
    }
    .someone-acc-about-sec {
        width: 100%;
        padding: 0px 5px;
        margin-top: 35px;
    }
    .someone-about-description {
        flex-direction: column;
        align-items: normal;
        justify-content: left;
        position: relative;
    }
    .someone-about-description > div:nth-child(1) {
        width: 100%;
    }
    /* .someone-suggestion {
        display: none;
    } */
    .someone-about-description > div > h2 {
        font-size: 18px;
    }
    .someone-about-description > div > p {
        font-size: 15px;
    }
    .action-btn {
        /* width: 100%; */
        width: 35%;
        /* gap: 5px; */
        /* align-items: flex-end; */
        /* justify-content: right; */
        /* margin-top: 10px; */

        position: absolute;
        top: -5px;
        right: 10px;

    }
    .action-btn > button {
        padding: 8px 10px;
    }
    .action-btn > button > img {
        height: 18px;
        width: 18px;
    }

    .someone-acc-friends > div > p {
        font-size: 18px;
    }
    .someone-acc-friends {
        padding: 0px 5px;
        width: 100%;
    }
    .someone-acc-friends > div > img {
        height: 40px;
        width: 60px;
    }
    .mutual-img-1 {
        right: 18%;
    }
    .mutual-img-2 {
        right: 36%;
    }
    .mutual-img-3 {
        right: 54%;
    } 
    .mutual-img-4 {
        right: 72%;
    }
    .someone-acc-friends > div:nth-child(2) > button {
        right: 70%;
    }

    .someone-middle-sec {
        flex-direction: column;
        gap: 20px;
    }
    .someone-suggestion {
        width: 100%;
        gap: 15px;
        overflow: scroll;
        padding: 0px 5px;
    }
    .someone-suggestion > div > img, .someone-suggestion > a > div > img {
        height: 110px;
        min-width: 110px;
        border-radius: 25px;
        cursor: pointer;
    }
    .someone-suggestion > div > div > p, .someone-suggestion > a > div > div > p{
        font-size: 13px;
    }
    .someone-acc-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* grid-template-rows: repeat(5, 1fr); */
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }
    .someone-acc-grid > div, .someone-acc-grid > a  {
        width: fit-content;
        justify-self: center;
    }
    .someone-acc-friends > div:nth-child(1) > button {
        margin-left: 120px;
    }
    .combine-friends-sec {
        width: 100%;
        padding: 20px 20px;
    }
    .my_friends {
        margin-top: 5px;
    }
}