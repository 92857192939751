.sub-idf-div {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 10px;
    font-family: 'ubuntu', sans-serif;
}
.sub-idf-div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
/* .three-middle-dot {
    position: absolute;
    margin: auto;
} */

/* .three-dots {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    margin-left: 6px;
    margin-bottom: 150px;
    font-size: 2rem;
}
.circle{
    display: inline-block;
    margin: 0px;
    margin-right:7px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgb(217,217,217);
}
.circle-color {
    background-color: rgb(189, 250, 60);
} */

.name, .username {
    font-size: 1.3rem;
    margin-bottom: 10px;

}

.name-input, .username-input{
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    height: 36px;
    width: 300px;
    text-align: center;
    font-size: 16px;
    border: 3px solid rgb(189, 250, 60);
    border-radius: 20px;
    margin-left: 15px;
    background-color: transparent;
}
.name-input,.username-input:focus {
    outline: none;
}
.name-input {
    margin-bottom: 50px;
}
/* .username-input{
    margin-bottom: 200px;
} */

/* .continue-button{
    text-align: center;
}
.continue-button button{
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 20px;
    padding: 10px 70px;
    margin-bottom: 10px;

    border: 1px solid rgb(188,187,187);
    border-radius: 10px;
    background-color: rgb(189, 250, 60);
    cursor: pointer;
    transition: opacity 0.15s;
} */
.continue-button button:hover{
    opacity: 0.8;
}
.continue-button button:active {
    opacity: 1;
}


.continue-button-position {
    /* width: fit-content; */
    width: 22%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    position: absolute;
    bottom: 10px;
}

.skip-button {
    text-align: center;
    
}
.skip-button button {
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 20px;
    padding: 10px 70px;
    cursor: pointer;
    border: none;
    background-color: white;
}
@media screen and (min-width: 320px) and (max-width: 570px) {
    .continue-button-position {
        right: 150px;
    }
    .idf-heading {
        font-size: 1.8rem;
    }
    .idf-heading-base {
        margin-bottom: 40px;
    }
}