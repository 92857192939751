/* body{
    padding: 0;
    margin: 0;
} */
/* .message-main {
    display: flex;
    font-family: 'Ubuntu',sans-serif;
    overflow-x: hidden;
} */
/* .left-side {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 0 25px;
    margin-top: 20px;

    position: sticky;
    top: 10px;
    height: 98vh;
} */
.home-right-side {
    display: flex;
    /* background-color: #8A8C8E; */
    height: 100vh;
    width: 100%;
    border-radius: 20px 0px 0px 20px;
    overflow-y: hidden;
}
.home-middle-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
.home-last-sec {
    width: 55%;
}
/* left side css */
/* .twigyy-logo-side-bar {
    height: 45px;
    filter: drop-shadow(4px 4px 0px #adadad);
}
.left-side > div {
    margin-bottom: auto;
}

.side-bar > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    background-color: #38424B;
    padding: 18px 15px;
    border-radius: 10px;       
}
.side-bar > div button{
    display: flex;
    border: none;
    align-items: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
}
.side-bar > div img {
    height: 28px;
}
.side-bar-normal-icon {
    background-color: transparent;
    border: none;
    border-radius: 50%;
}
.side-bar-focus-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #B9F53B;
    border-radius: 50%;
    border: none;
}
.side-bar-focus-icon > img {
    filter: invert();
} */

/* Right side css */

.profile-head {
    /* width: 94%; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px;
    margin-bottom: 10px;
}
.profile-head > section, .profile-head > section > a {
    display: flex;
    width: max-content;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    /* margin-right: 25%;
    margin-left: 10%; */
    gap: 20px;
    /* padding: 0px 10px; */
}
.profile-head > section > img, .profile-head > section > a > img {
    height: 43px;
    width: 43px;
    border-radius: 50%;
    object-fit: cover;
} 
.profile-head > section > p, .profile-head > section > a > p {
    color: #FFFF;
    font-size: 20px;
    margin: 0;
    letter-spacing: 2px;
}
.profile-head > div {
    /* width: 20%; */
    width: 5%;
    display: flex;
    justify-content: space-around;
}
.profile-head > div > img {
    height: 20px;
}


div.home-middle-sec > hr {
    width: 90%;
    border: 1px solid #a4a4a4;
    border-radius: 1px;
}
.middle-second-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;   
}
.middle-second-sec > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #A7DD35;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin-bottom: 30px;
}
.middle-second-sec > div > img {
    height: 65px;
}
.middle-second-sec > p {
    color: #FFFF;
    font-size: 18px;
    font-weight: 100;
    margin: 0;
    margin-bottom: 30px;
    letter-spacing: 2px;
}
.middle-second-sec > section {
    display: flex;
    gap: 20px;
}
.middle-second-sec > section > button, .middle-second-sec > section > a > button  {
    background-color: #FFFF;
    font-family: 'Ubuntu',sans-serif;
    font-size: 19px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    padding: 13px 15px;
    cursor: pointer;
}
.middle-second-sec > section > button:hover, .middle-second-sec > section > a > button:hover {
    background-color: #ffffffae;
    /* background-color: #befa3cd0; */
}

.home-right-side > hr {
    border: 1px solid #a4a4a4;
    border-radius: 1px;
    margin: auto;
    margin-left: 0;
    margin-right: 0;
    height: 90%;
}

/* Last sec css */

.home-cover-img{
    height: 300px;
    width: 100%;

    /* border-top-left-radius: 20px; */
    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 80px;
    border-top-right-radius: 20px;
    /* filter: blur(5.5px); */
}

.msg-head {
    display: flex;
    align-items: center;
    padding: 35px 30px 18px 35px;
    position: relative;
    top: -310px;
}
.msg-head > p {
    color: #FFFF;
    font-family: 'Ubuntu',sans-serif;
    font-size: 25px;
    font-weight: 700px;
    letter-spacing: 3px;
    margin: 0;
    margin-right: auto;
}
.msg-head > a, .msg-head > a > img{
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

/* Message sec css */
.messages-sec {
    /* 88px */
    height: 73vh;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 22px;
    position: relative;
    /* top: -310px; */
    top: -200px;
    /* padding-top: 15px; */
    padding: 20px 0px 20px 0px;
    overflow: auto;
}
.messages-sec::-webkit-scrollbar {
    display: none;
}
.messages-sec > a {
    display: flex;
    justify-content: center;
    width: 100%;
    text-decoration: none;
}
.messages-sec > a:focus,.messages-sec > a:hover,.messages-sec > a:active {
    text-decoration: none;
}

.incomming-msg {
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.incomming-msg > a {
    display: flex;
    align-items: center;
    width: 80%;
    /* background-color: #4B4848; */

    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .3); 
    background-color: rgba(75, 72, 72, .6);
    backdrop-filter: blur(4px);

    padding: 18px 10px;
    border-radius: 18px;
    cursor: pointer;
    position: relative;
}
.incomming-msg > a > img {
    height:40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}
.incomming-msg > a > div:nth-child(2){
    width: 65%;
}

.incomming-msg > a > div:nth-child(2) > *{
    font-size: 14px;
    color: #FFFF;
    margin: 0;
    margin-left: 20px;
}
.incomming-msg > a > div:nth-child(2) > p{
    margin-top: 10px;
}
.incomming-msg > a > div:nth-child(3) > p{
    font-size: 14px;
    color: #FFFF;
    margin: 0;
    text-wrap: nowrap;
}

.msg-indicator {
    width: 15px;
    height: 15px;
    background-color: #8bc60a;
    border-radius: 50%;
    color: white;
    position: absolute;
    top: 6px;
    right: 6px;
    text-align: center;
}

.unseen-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    font-size: 14px;
    background-color: red;
    border-radius: 50%;
    color: #FFFF;
    margin: 0;
    margin-left: auto;
}

/* Chat Sec */
.chat-sec {
    width: 90%;
    height: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
}

.chat-sec::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }

.chat-sec > p {
    color: white;
}
.msg_send_img {
    /* border-radius: 18px; */
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.frnd_msg_send_img {
    /* border-radius: 18px; */
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
}
.frnd-msg {
    width: 100%;
    display: flex;
    align-items: center;
}
.frnd-msg > img {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-right: 20px;
}
.frnd-msg > div > div {
    max-width: 400px;
    /* text-wrap: wrap; */
    word-wrap: break-word;
    background-color: #CBC698;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 5px 10px;
}
.frnd-msg > div > div > p {
    margin: 0;
    font-size: 1.3rem;
}
.frnd-msg > div > p {
    margin: 0;
    color: white;
    font-size: 0.9rem; 
    margin-top: 5px;   
}

.my-msg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.my-msg > img {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-left: 20px;
}
.my-msg > div > div {
    max-width: 400px;
    /* text-wrap: wrap; */
    word-wrap: break-word;
    background-color: #9BB439;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 5px 10px;
}
.my-msg > div > div > p {
    margin: 0;
    font-size: 1.3rem;
}
.my-msg > div {
    display: flex;
    flex-direction: column;
    align-items: self-end;
}
.my-msg > div > p {
    margin: 0;
    font-size: 0.9rem; 
    margin-top: 5px;   
    color: white;
}


.msg-text {
    width: 90%;
    display: flex;
    justify-content: center;
}
.msg-text > div {
    width: 95%;
    display: flex;
    align-items: center;
    background-color: #C4C2C2;
    border-radius: 15px;
    padding: 5px;
}
.msg-text > div > input {
    width: 100%;
    outline: none;
    background-color: transparent;
    border: none;
}
.msg-text > div > img {
    height: 25px;
    width: 25px;
    margin-left: 5px;
    margin-right: 10px;
    cursor: pointer;
}
.msg-text > section{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #A7DD35;
    height: 40px;
    width: 40px;
    border-radius: 50%; 
    margin-left: 20px;
    cursor: pointer;
}
.msg-text > section > img {
    height: 20px;
    margin-left: 3px;
}

.emoji-picker{
    position: absolute;
    bottom: 75px;
    right: 140px;
    
}
.chat-friends-main {
    /* width: 100%; */
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.chat-friends-sec {
    position: absolute;
    bottom: 0px;
    /* background-color: #38424B; */
    /* background-color: #141414cc; */
    width: 80%;
    height: 90%;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    padding: 30px 30px;
}
.chat-friends-sec > section:nth-child(1) > div {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
}
.chat-friends-sec > section:nth-child(1) > div > p {
    font-size: 18px;
    color: white;
}
.chat-friends-sec > section:nth-child(1) > div > button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    padding: 5px;
    background-color: white;
    clip-path: circle();
}
.chat-my-friends {
    height: 100%;
}
.chat-my-friends > div{
    height: 80%;
    overflow: scroll;
    scrollbar-width: none;
    padding-bottom: 50px;
    /* background-color: red; */
}
.chat-my-friends > p {
    font-size: 18px;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}
.chat-my-friends > div > div, .chat-my-friends > div > a {
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}
.chat-my-friends > div > div:hover, .chat-my-friends > div > a:hover {
    background-color: #4c555ed6;
}
.chat-my-friends > div > div > img, .chat-my-friends > div > a > img {
    height: 40px;
    width: 40px;
}
.chat-my-friends > div > div > p:nth-child(2), .chat-my-friends > div > a > p:nth-child(2) {
    font-size: 18px;
}
.chat-my-friends > div > div > p:nth-child(3), .chat-my-friends > div > a > p:nth-child(3) {
    font-size: 15px;
}



@media screen and (min-width: 320px) and (max-width: 570px) {
    .msg-display {
        display: none;
    }
    .home-last-sec {
        width: 100%;
    }
    .home-right-side {
        border-radius: 10px 0px 0px 10px;
    }
    .home-cover-img {
        border-top-right-radius: 0px;
    }
    .home-middle-sec {
        height: 100vh;
        /* background-color: #8A8C8E; */
    }
    .profile-head > section {
        margin-left: 10px;
        gap: 10px;
    }
    .chat-sec {
        height: 100%;
        padding-bottom: 10px;
    }
    .msg-text {
        padding-top: 5px;
        padding-bottom: 10px;
    }
    .profile-head > section > img {
        height: 30px;
        width: 30px;
    }
    .profile-head > section > p {
        font-size: 16px;
    }
    .profile-head > div {
        gap: 15px;
    }
    .profile-head > div > img {
        width: 20px;
    }
    .frnd-msg > div > div > p {
        font-size: 1rem;
    }
    .my-msg > div > div {
        max-width: 80vw;
    }
    .my-msg > div > div > p {
        font-size: 1rem;
    }
    .emoji-picker {
        bottom: 55px;
        right: 0px;
    }
    .messages-sec {
        top: -286px;
    }
}