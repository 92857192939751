.mobile-nav-display {
    display: none;
}
@media screen and (max-width: 570px) {
.mobile-nav-display {
    display: contents;
}
.mobile-nav-outlet {
    /* display: contents; */
    position: absolute;
    /* bottom: 0px; */
    /* height: 100dvh; */
    width: 100%;
    /* height: fit-content; */
}
}